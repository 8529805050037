import { cloneDeep } from 'lodash';

/**
 * DataHelpers
 * This class contains a list of different helpers for data. This is a stand alone class.
 */
export default class DataHelpers {
    /**
     * Clone data
     * @param {*} data
     * @returns {*} data
     */
    static clone(data) {
        // Use clone deep to copy
        const dataCloned = cloneDeep(data);
        return dataCloned;
    }

    /**
     * Get a value from a model
     * @param {*} model The model, e.g. social.text
     * @param {*} baseObject  The object to fetch it from
     * @param {*} returnIfNotFound Value to return in case of an error
     */
    static getValue(baseObject, model, returnIfNotFound = undefined) {
        // Try the eval, and if failed, return undefined
        try {
            const patt = /^(\w|\.|-\[\])+$/;
            if (patt.test(model)) {
                let val = eval('baseObject.' + model); // eslint-disable-line
                if (val) {
                    return val;
                }
            }
            return returnIfNotFound;
        } catch (e) {
            return returnIfNotFound;
        }
    }

    /**
     * Set a model in an object
     * @param {*} baseObject  The object to start with
     * @param {*} model The model (the path in the model)
     * @param {*} value The value (the value)
     */
    static setModel(baseObject, model, value) {
        let activeObject = baseObject;
        const modelParts = model.split('.');

        modelParts.forEach((part, i) => {
            if (typeof activeObject[part] !== 'object' || activeObject[part] === null || Array.isArray(activeObject[part])) {
                activeObject[part] = {};
            }

            if (modelParts.length - 1 == i) {
                activeObject[part] = value;
            }

            activeObject = activeObject[part];
        });

        return baseObject;
    }

    /**
     * Remote item
     * @param {*} baseObject  The object to start with
     * @param {*} model The model (the path in the model)
     */
    static removeItem(baseObject, model) {
        try {
            const patt = /^(\w|\.|-\[\])+$/;
            if (patt.test(model)) {
                eval('delete baseObject.' + model); // eslint-disable-line
            } else {
                return baseObject;
            }
        } catch (e) {
            console.log('Error removing', e);
        }

        return baseObject;
    }
}
