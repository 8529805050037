export default class ColumnHelpers {
    /**
     * Calculate the number of coluns to use based on the desired column width.
     * @param containerWidth
     * @param columnWidth
     * @returns
     */
    static getColumnCount = (containerWidth = 1200, columnWidth = 300): number => {
        const columnCount = Math.floor(containerWidth / columnWidth);
        if (columnCount < 1) return 1;
        return columnCount;
    };
}
