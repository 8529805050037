export default class CommentReviewHelpers {
    /**
     * Format the display of a mentioned user.
     * @param {string} id
     * @param {array} users
     * @returns display name for use in comments
     */
    static displayUser = (id, users = []) => {
        let display = id;
        const thisUser = users.find((u) => u.id === id);
        if (thisUser && thisUser.display) display = thisUser.display;
        return display;
    };

    /**
     * Format the display of a mentioned user.
     * @param {string} id
     * @param {array} users
     */
    static currentUser = (id, users = []) => {
        return users.find((u) => u.id === id);
    };
}
