import moment from 'moment';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import FeedRequest from './feed-request';
import ComponentStore from '../ComponentStore';
import LWFiles from '../Files';
import Translation from '../Translation';

/**
 * Feed manager helpers
 * This class contains some helpers functions for the feed manager
 */
class FeedHelpers {
    // Cron schedule to use in cases a cron should never run.
    static neverCron = '0 0 29 2 *';

    static feedCrons = {
        DAILY: Translation.get('feeds.addForm.scheduleUpdateOptions.daily', 'feed-management'),
        '*/10 * * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.10minutes', 'feed-management'),
        '*/30 * * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.30minutes', 'feed-management'),
        '0 * * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.hour', 'feed-management'),
        '0 */3 * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.3hours', 'feed-management'),
        '0 0 * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.midnight', 'feed-management'),
        '0 3 * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.scheduled', 'feed-management') + '3:00 GMT',
        '0 6 * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.scheduled', 'feed-management') + '6:00 GMT',
        '0 9 * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.scheduled', 'feed-management') + '9:00 GMT',
        '0 12 * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.scheduled', 'feed-management') + '12:00 GMT',
        '0 15 * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.scheduled', 'feed-management') + '15:00 GMT',
        '0 18 * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.scheduled', 'feed-management') + '18:00 GMT',
        '0 21 * * *': Translation.get('feeds.addForm.scheduleUpdateOptions.scheduled', 'feed-management') + '21:00 GMT',
        [FeedHelpers.neverCron]: Translation.get('feeds.addForm.scheduleUpdateOptions.noAutoUpdate', 'feed-management')
    };

    static feedTypes = {
        '': '',
        csv: 'CSV',
        rss: 'RSS',
        googleShopping: 'Google Shopping',
        spreadsheet: 'Google Spreadsheet',
        xml: 'XML',
        xlsx: 'XLSX / XLS',
        json: 'JSON'
    };

    static outputTypes = {
        download: Translation.get('editor.outputForm.outputTypes.shareUrl', 'feed-management'),
        spreadsheet: Translation.get('editor.outputForm.outputTypes.spreadsheet', 'feed-management'),
        cloud: Translation.get('editor.outputForm.outputTypes.file', 'feed-management')
    };

    static updateActions = {
        append: Translation.get('feeds.addForm.updateActionOptions.append', 'feed-management'),
        replace: Translation.get('feeds.addForm.updateActionOptions.replace', 'feed-management')
    };

    // Automated mapping options for data feed
    static mapOptionValues = {
        title: ['title', 'header', 'name', 'headline'],
        subtitle: ['subtitle', 'description', 'subheader'],
        image: [
            'image',
            'enclosure',
            'picture',
            'photo',
            'illustration',
            'photograph',
            'portrait',
            'image_link',
            'additional_image_link',
            'imageUrl',
            'ImageUrl',
            'productImage',
            'product_image',
            'imageProduct',
            'backgroundImage'
        ],
        category: ['category', 'categories', 'google_product_category'],
        label: ['label'],
        chip: ['scheduleName', '_scheduleName', 'feedName', '_feedName', 'cta', 'chip'],
        url: ['url', 'link', 'displayUrl', 'website', 'site', 'location', 'link', 'ArtikelUrl', 'Exit_URL']
    };

    /**
     * Calculate the time from now of the next execution of a cronjob.
     * @param {string} cron
     */
    static timeToCron = (cron) => {
        switch (cron) {
            case '*/10 * * * *':
                // every 10 minutes
                return moment
                    .utc()
                    .add(10 - (moment.utc().minutes() % 10), 'minutes')
                    .subtract(moment.utc().seconds(), 'seconds')
                    .local()
                    .fromNow();
            case '*/30 * * * *':
                // every 30 minutes
                return moment
                    .utc()
                    .add(30 - (moment.utc().minutes() % 30), 'minutes')
                    .subtract(moment.utc().seconds(), 'seconds')
                    .local()
                    .fromNow();
            case '0 * * * *':
                // every hour
                return moment.utc().add(1, 'hours').subtract(moment.utc().minutes(), 'minutes').subtract(moment.utc().seconds(), 'seconds').local().fromNow();
            case '0 */3 * * *':
                // every 3 hours
                return moment
                    .utc()
                    .add(3 - (moment.utc().hours() % 3), 'hours')
                    .subtract(moment.utc().minutes(), 'minutes')
                    .subtract(moment.utc().seconds(), 'seconds')
                    .local()
                    .fromNow();
            case '0 0 * * *':
                // every day at midnight
                return moment
                    .utc()
                    .add(1, 'days')
                    .subtract(moment.utc().hours(), 'hours')
                    .subtract(moment.utc().minutes(), 'minutes')
                    .subtract(moment.utc().seconds(), 'seconds')
                    .local()
                    .fromNow();
            case 'DAILY':
                return Translation.get('feeds.feedUpdates.onceADay', 'feed-management');
            case FeedHelpers.neverCron:
                return null;
            default:
                console.error('cron schedule not defined');
                return null;
        }
    };

    /**
     * Return all feeds from an account or campaign
     * @param {*} campaignId
     * @param {*} searchTerm
     */
    static getAllFeeds = (campaignId = null, searchTerm = '') => {
        return new Promise((resolve, reject) => {
            let url = 'dataset';
            if (campaignId) {
                url = url + '?campaignId=' + campaignId;
            }

            if (searchTerm) {
                let queryPrefix = '?';
                if (campaignId) {
                    queryPrefix = '&';
                }

                url = url + queryPrefix + `searchTerm=${searchTerm}`;
            }

            try {
                FeedRequest.get(url)
                    .then((result) => {
                        let list = [];
                        if (result && result.data) {
                            list = result.data;
                        }
                        resolve(list);
                    })
                    .catch((error) => {
                        SnackbarUtils.error('Error when loading all feeds');
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Get all feeds from campaign id
     * @param {*} campaignId
     */
    static async getFeedByCampaignId(campaignId) {
        try {
            const allFeeds = await FeedHelpers.getAllFeeds(campaignId);
            return allFeeds[0];
        } catch (error) {
            console.log('Cannot load feed', error);
            return false;
        }
    }

    /**
     * Get feed by name
     * @param {*} searchTerm
     */
    static async getFeedByTitle(searchTerm) {
        try {
            const allFeeds = await FeedHelpers.getAllFeeds();
            if (allFeeds) {
                for (const i in allFeeds) {
                    if (
                        allFeeds[i].customData &&
                        allFeeds[i].customData.title &&
                        allFeeds[i].customData.title.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                        return allFeeds[i];
                    }
                }
            }
        } catch (error) {
            console.log('Cannot load feed', error);
            return false;
        }
        return false;
    }

    /**
     * Returns the records of a specific feed. The default is 100
     * @param {*} feedId The specific feed id
     * @param {*} limit The amount of records you want to return
     */
    static getRecordsFromFeed = (dataSetId, limit = 100, returnDataFields = false) => {
        return new Promise((resolve, reject) => {
            try {
                if (dataSetId) {
                    FeedRequest.post(`dataset/${dataSetId}/item/search?limit=${limit}`)
                        .then((data) => {
                            let list = [];

                            if (data && data.data) {
                                list = data.data.data;
                            }

                            // Return actual values in the feeds
                            if (returnDataFields) {
                                list.forEach((item, i) => {
                                    list[i] = item.data;
                                });
                            }

                            resolve(list);
                        })
                        .catch(() => {
                            SnackbarUtils.error('Failed to retrieve the records of the dataset');
                        });
                } else {
                    reject();
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Returns the records from a data set.
     * This is a sync version.
     * @param {*} dataSetId The specific data set id
     * @param {*} limit The amount of records you want to return
     */
    static getRecordsFromFeedSync = (dataSetId, limit = 100, returnDataFields = false) => {
        return new Promise((resolve, reject) => {
            try {
                if (dataSetId) {
                    FeedRequest.post(`dataset/${dataSetId}/item/search?limit=${limit}`)
                        .then((data) => {
                            let list = [];

                            if (data && data.data) {
                                list = data.data.data;
                            }

                            // Return actual values in the feeds
                            if (returnDataFields) {
                                list.forEach((item, i) => {
                                    list[i] = item.data;
                                });
                            }

                            resolve(list);
                        })
                        .catch((error) => {
                            SnackbarUtils.error('Failed to retrieve the records of the dataset');
                            console.log('Failed to retrieve the records of the dataset', error);
                        });
                } else {
                    SnackbarUtils.error('No feedId is given');
                    reject();
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Returns records of a specific page in the feed manager
     * @param {*} dataSetId The specific data set id
     * @param {*} page The page you want to return
     * @param {*} recordsPerPage The amount of records each page has (default 25)
     */
    static getRecordsFromPage = (dataSetId, page = 0, recordsPerPage = 25) => {
        let limit = recordsPerPage;

        if (page > 0) {
            limit = recordsPerPage * (page + 1);
        }

        return new Promise((resolve, reject) => {
            try {
                if (dataSetId) {
                    FeedRequest.post(`dataset/${dataSetId}/item/search?limit=${limit}`)
                        .then((data) => {
                            let list = [];

                            if (data && data.data) {
                                list = data.data.data;
                            }

                            list = list.slice(-1 * recordsPerPage);

                            resolve(list);
                        })
                        .catch(() => {
                            SnackbarUtils.error('Failed to retrieve the records of the dataset');
                        });
                } else {
                    SnackbarUtils.error('No feedId is given');
                    reject();
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Returns a single record from a data set
     * @param {*} dataSetId The specific data set id
     * @param {*} recordId The record you want to return
     */
    static getSingleRecordFromFeed = (dataSetId, recordId) => {
        return new Promise((resolve, reject) => {
            try {
                if (dataSetId) {
                    FeedRequest.get(`dataset/${dataSetId}/item/${recordId}`)
                        .then((data) => {
                            let item = {};

                            if (data && data.data) {
                                item = data.data.data;
                            }

                            resolve(item);
                        })
                        .catch((error) => {
                            SnackbarUtils.error('Failed to retrieve the record from the feed');
                            console.log('Failed to retrieve the record from the feed', error);
                            reject(error);
                        });
                } else {
                    SnackbarUtils.error('No feedId is given');
                    reject();
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Initialize the ComponentStore for FeedManager.
     */
    static init = () => {
        ComponentStore.setData('FeedManager', {
            dataSetLoadError: false,
            dataSetList: [],
            dataSetListLoaded: false,
            lastPatch: {}
        });
    };

    /**
     * Load the list with all feeds and set them in Redux
     */
    static loadData = () => {
        FeedRequest.get('dataset')
            .then((data) => {
                const list = data.data;

                ComponentStore.setData('FeedManager', {
                    dataSetLoadCompleted: true,
                    dataSetLoadError: false,
                    dataSetList: list,
                    dataSetListLoaded: true,
                    dataSetListReloaded: Date.now(),
                    lastPatch: {}
                });
            })
            .catch(() => {
                SnackbarUtils.error('Error when loading datasets');

                ComponentStore.setData('FeedManager', {
                    dataSetLoadCompleted: true,
                    dataSetLoadError: true,
                    dataSetList: [],
                    dataSetListLoaded: true,
                    lastPatch: {}
                });
            });
    };

    /**
     * Replace a data set in Redux.
     * @param {object} newDataSet
     */
    static replaceDataSet = (newDataSet) => {
        const { dataSetList, dataSet, latestUpdatePatch, latestPublishPatch } = ComponentStore.get('FeedManager');
        const dataSetIndex = dataSetList.findIndex((ds) => ds._id === newDataSet._id);
        delete newDataSet.__v;
        dataSetList.splice(dataSetIndex, 1, newDataSet);

        // Check if the currently openend dataset is the dataset being updated
        let dataSetActive = false;
        if (dataSet && dataSet._id === newDataSet._id) {
            dataSetActive = true;
        }

        // Check if the last Redux update patch concerned this dataset. If so rest the latestUpdatePatch key in Redux.
        let latestUpdatePatchActive = false;
        if (latestUpdatePatch && latestUpdatePatch.dataSetId && latestUpdatePatch.dataSetId === newDataSet._id) {
            latestUpdatePatchActive = true;
        }

        // Check if the last Redux publish patch concerned this dataset. If so rest the latestPublishPatch key in Redux.
        let latestPublishPatchActive = false;
        if (latestPublishPatch && latestPublishPatch.dataSetId && latestPublishPatch.dataSetId === newDataSet._id) {
            latestPublishPatchActive = true;
        }

        ComponentStore.setData('FeedManager', {
            dataSetLoadCompleted: true,
            dataSetLoadError: false,
            dataSetList,
            dataSet: dataSetActive ? newDataSet : dataSet,
            latestUpdatePatch: latestUpdatePatchActive ? {} : latestUpdatePatch,
            latestPublishPatch: latestPublishPatchActive ? {} : latestPublishPatch
        });
    };

    /**
     * Load a specific dataset and replace it in Redux.
     * @param {string} dataSetId
     */
    static reloadDataSet = (dataSetId) => {
        FeedRequest.get(`dataset/${dataSetId}`)
            .then((data) => {
                FeedHelpers.replaceDataSet(data.data);
            })
            .catch((error) => {
                console.log('Error when loading dataset', error);
                SnackbarUtils.error('Error when loading dataset');

                ComponentStore.setData('FeedManager', {
                    dataSetLoadCompleted: true,
                    dataSetLoadError: true
                });
            });
    };

    /**
     * Set a dataSet as active and add it to the the list if it wasn't in the list.
     * Otherwise, eplace the item in the list.
     * @param {object} dataSet
     */
    static setActiveDataSet = (dataSet) => {
        const { dataSetList } = ComponentStore.get('FeedManager');
        const dataSetIndex = dataSetList.indexOf((set) => set._id === dataSet._id);
        if (dataSetIndex > 0) {
            dataSetList.splice(dataSetIndex, 1, dataSet);
        } else {
            dataSetList.push(dataSet);
        }

        ComponentStore.setData('FeedManager', {
            dataSetList,
            dataSet
        });
    };

    /**
     * Set the laoding complete key in Redux.
     * @param {boolean} value
     */
    static setLoading = (value) => {
        ComponentStore.setData('FeedManager', {
            dataSetLoadCompleted: !value
        });
    };

    /** return a formatted string of a feed's last import date
     * @param {object} feed - feed or schedule item
     * @param {string} nodate - text t display if no date is found
     */
    static formatLatestUpdateDate = (latestUpdate, nodate = Translation.get('feedHelpers.notImported', 'feed-management')) => {
        let latestUpdateDate = null;

        if (latestUpdate && typeof latestUpdate === 'object') {
            latestUpdateDate = latestUpdate.date;
        } else if (latestUpdate && typeof latestUpdate === 'string') {
            latestUpdateDate = latestUpdate;
        }

        if (latestUpdateDate) {
            return moment(latestUpdateDate).fromNow();
        } else {
            return nodate;
        }
    };

    /**
     * Patch the latestUpdate object of a schedule of a feed in Redux
     * @param {string} dataSetId
     * @param {string} type the type of webscocket message that initiated this patch
     * @param {object} latestUpdate patch to apply on the latestUpdate object
     */
    static patchDataSetUpdate = (dataSetId, type, latestUpdate) => {
        const { dataSetList, dataSet } = ComponentStore.get('FeedManager');

        const dataSetIndex = dataSetList.findIndex((ds) => ds._id === dataSetId);

        if (dataSetIndex >= 0) {
            // Recalculate the itemCount based on created and deleted rows.
            let itemCount = dataSetList[dataSetIndex] && dataSetList[dataSetIndex].metadata && dataSetList[dataSetIndex].metadata.itemCount;
            if (latestUpdate.deleted !== undefined && latestUpdate.created !== undefined) {
                itemCount = itemCount - latestUpdate.deleted + latestUpdate.created;
            }

            // Keep old operationId during update, so display doesn't shift.
            const operationId =
                dataSetList[dataSetIndex].latestUpdate && dataSetList[dataSetIndex].latestUpdate.operationId
                    ? dataSetList[dataSetIndex].latestUpdate.operationId
                    : null;

            const patchedDataSet = {
                ...dataSetList[dataSetIndex],
                metadata: {
                    ...dataSetList[dataSetIndex].metadata,
                    itemCount
                },
                latestUpdate: {
                    operationId,
                    ...latestUpdate
                }
            };

            dataSetList.splice(dataSetIndex, 1, patchedDataSet);

            // Check if the currently openend dataset is the dataset being patched
            let dataSetActive = false;
            if (dataSet && dataSet._id === dataSetId) {
                dataSetActive = true;
            }

            ComponentStore.setData('FeedManager', {
                dataSetList,
                dataSet: dataSetActive ? patchedDataSet : dataSet,
                latestUpdatePatch: {
                    type,
                    dataSetId,
                    ...latestUpdate
                }
            });
        }
    };

    /**
     * Patch the latestUpdate object of a schedule of a feed in Redux
     * @param {string} dataSetId
     * @param {string} type the type of webscocket message that initiated this patch
     * @param {object} latestUpdate patch to apply on the latestUpdate object
     */
    static patchDataSetPublish = (dataSetId, type, latestPublish) => {
        const { dataSetList, dataSet } = ComponentStore.get('FeedManager');

        const dataSetIndex = dataSetList.findIndex((ds) => ds._id === dataSetId);

        if (dataSetIndex >= 0) {
            const patchedDataSet = {
                ...dataSetList[dataSetIndex],
                latestPublish: {
                    ...dataSetList[dataSetIndex].latestPublish,
                    ...latestPublish
                }
            };

            dataSetList.splice(dataSetIndex, 1, patchedDataSet);

            // Check if the currently openend dataset is the dataset being patched
            let dataSetActive = false;
            if (dataSet && dataSet._id === dataSetId) {
                dataSetActive = true;
            }

            ComponentStore.setData('FeedManager', {
                dataSetList,
                dataSet: dataSetActive ? patchedDataSet : dataSet,
                latestPublishPatch: {
                    type,
                    dataSetId,
                    ...latestPublish
                }
            });
        }
    };

    /**
     * Checks if a string is a valid url
     * @param {string} value
     * @returns true or false
     */
    static isValidUrl = (value) => {
        if (!value || typeof value !== 'string') return false;

        const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/; // eslint-disable-line

        if (regex.test(value) && (value.startsWith('http') || value.startsWith('https') || value.startsWith('www'))) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Try to determine whether a key value pair contains an url to an image.
     * @param {string} key
     * @param {*} value
     */
    static isImage = (key, value) => {
        if (typeof value === 'string' && FeedHelpers.isValidUrl(value)) {
            if (value.endsWith('jpg')) return true;
            if (value.endsWith('jpeg')) return true;
            if (value.endsWith('png')) return true;
            if (value.endsWith('gif')) return true;
            if (FeedHelpers.mapOptionValues['image'].includes(key)) return true;
            return false;
        }
        return false;
    };

    /**
     * This function checks if there are still missing important fields in the DataCard. If there are, it tries to find
     * values by checking the keys and values of the data object, or tries to find a title/image based on the values
     * @param {*} data
     * @param {*} mappedData
     */
    static smartCheck = (data, mappedData, usedKeys = []) => {
        const smartMappedData = { ...mappedData };
        const optionalValues = FeedHelpers.mapOptionValues;
        const cardValues = Object.keys(optionalValues);
        let missingValues = cardValues.filter((v) => !Object.keys(mappedData).includes(v));

        // Check for the missing values in the keys of the data
        missingValues.forEach((missingValue) => {
            Object.keys(data).forEach((dataKey) => {
                // It checks the keys and values until it finds a match and then stops the loop
                let found = false;

                if (typeof data[dataKey] !== 'string' || dataKey.startsWith('_')) return;

                if (usedKeys.includes(dataKey)) return;

                optionalValues[missingValue].forEach((optionalValue) => {
                    const hasMissingValueInKey = dataKey.toLowerCase().includes(optionalValue);
                    const hasMissingValueInValue = data[dataKey].toLowerCase().includes(optionalValue);

                    // Check if the key contains one of the missing card values
                    if (hasMissingValueInKey || hasMissingValueInValue) {
                        smartMappedData[missingValue] = data[dataKey];

                        // A match is found, so remove this from the missing values (we don't have to check it again)
                        missingValues = missingValues.filter((v) => v !== missingValue);
                        found = true;
                    }
                });

                if (found) return true;
            });
        });

        // If there is still no title, check if there is a string value between 3 and 50 characters and choose that as a title
        if (missingValues.includes('title')) {
            Object.keys(data).forEach((dataKey) => {
                const value = data[dataKey];
                if (typeof value === 'string' && !FeedHelpers.isValidUrl(value) && !usedKeys.includes(dataKey) && !dataKey.startsWith('_')) {
                    if (value.length > 3 && value.length < 50) {
                        smartMappedData['title'] = data[dataKey];
                        missingValues = missingValues.filter((v) => v !== 'title');
                        usedKeys.push(dataKey);
                        return true;
                    }
                }
            });
        }
        // If there is still no subtitle, check if there is a string value between 3 and 50 characters and choose that as a title
        if (missingValues.includes('subtitle')) {
            Object.keys(data).forEach((dataKey) => {
                const value = data[dataKey];
                if (typeof value === 'string' && !FeedHelpers.isValidUrl(value) && !usedKeys.includes(dataKey) && !dataKey.startsWith('_')) {
                    if (value.length > 3 && value.length < 50) {
                        smartMappedData['subtitle'] = data[dataKey];
                        missingValues = missingValues.filter((v) => v !== 'subtitle');
                        usedKeys.push(dataKey);
                        return true;
                    }
                }
            });
        }

        // If there is still no image, check the values and try to find a url to an image
        if (missingValues.includes('image')) {
            Object.keys(data).forEach((dataKey) => {
                const value = data[dataKey];
                if (FeedHelpers.isImage(dataKey, value)) {
                    smartMappedData['image'] = data[dataKey];
                    missingValues = missingValues.filter((v) => v !== 'image');
                }
            });
        }

        return smartMappedData;
    };

    /**
     * Returns the value of the mapped data and checks for dot notation
     * @param {*} data
     * @param {*} possibleValue
     * @returns The value of the mapped data
     */
    static checkDotNotation = (data = {}, possibleValue = '') => {
        if (possibleValue.split('.').length === 0) {
            return data[possibleValue];
        } else {
            let value;

            // Accept dot notation in a key
            const splittedValueArray = possibleValue.split('.');
            const key = splittedValueArray[0];
            const key2 = splittedValueArray[1];
            const key3 = splittedValueArray[2];
            const key4 = splittedValueArray[3];

            try {
                if (key4) {
                    value = data[key][key2][key3][key4];
                } else if (key3) {
                    value = data[key][key2][key3];
                } else if (key2) {
                    value = data[key][key2];
                } else {
                    value = data[key];
                }
            } catch (error) {
                // Empty record
            }

            return value;
        }
    };

    /**
     * Maps the source data to a structure the DataCard can read
     * The keys can be different for each source, so we loop through a list of possible values
     * You can also give additional possible values via the mapping props
     * @param {object} data
     * @param {object} datacardMapping
     */
    static mapDataForDisplay = (data, datacardMapping) => {
        let mappedData = {};
        const optionalValues = FeedHelpers.mapOptionValues;
        const usedKeys = [];

        // Overwrite the optional values if a manual datacardMapping is given
        if (datacardMapping) {
            Object.keys(datacardMapping).forEach((key) => {
                optionalValues[key] = [datacardMapping[key]];
            });
        }

        // Set the values to the mappedData object
        Object.keys(optionalValues).forEach((key) => {
            optionalValues[key].forEach((possibleValue) => {
                if (Object.keys(data).includes(possibleValue)) {
                    // The data includes the key
                    mappedData[key] = data[possibleValue];
                    usedKeys.push(possibleValue);
                } else if (FeedHelpers.checkDotNotation(data, possibleValue)) {
                    // The data does not include the key, but we check if there is a dot notation and if so, return the nested value
                    mappedData[key] = FeedHelpers.checkDotNotation(data, possibleValue);
                    usedKeys.push(possibleValue);
                }
            });
        });

        // Check if the image url is inside of an object
        // First check if there is an url inside of the object, otherwise loop through the possible values above
        if (mappedData.image && typeof mappedData.image === 'object') {
            if (mappedData.image.url) {
                mappedData.image = mappedData.image.url;
            } else {
                optionalValues.image.forEach((possibleValue) => {
                    if (Object.keys(mappedData.image).includes(possibleValue)) {
                        mappedData.image = mappedData.image[possibleValue];
                    }
                });
            }
        }

        // This functions checks if there are still any missing values and tries to find correct values for it
        mappedData = FeedHelpers.smartCheck(data, mappedData, usedKeys);

        return mappedData;
    };

    /**
     * Check if some items in the structure have a specific displayType.
     * @param {array} structure
     * @returns array of displat types
     */
    static getDisplayTypes = (structure = []) => {
        const displayTypes = [];

        structure.forEach((item) => {
            if (item.displayType) {
                displayTypes.push({
                    key: item.key,
                    displayType: item.displayType
                });
            }
        });

        return displayTypes;
    };

    /**
     * The structure defines the columns / fields to display for the records.
     * For an in campaign data set it can be passed as a prop to the DataSetManager block [structure].
     * For a manual data set it should be defined by the user and stored in dataSet.customData.dataSetStructure
     * For an imported feed, the backend defines the structure based on the input and stores it in dataSet.structure. This structure should be reformatted.
     * @param {object} dataSet
     * @param {array} structure
     * @returns
     */
    static getStructure = (dataSet) => {
        if (dataSet && dataSet.customData && dataSet.customData.dataSetStructure && dataSet.customData.dataSetStructure.length) {
            return dataSet.customData.dataSetStructure;
        }
        if (dataSet && dataSet.structure && Object.keys(dataSet.structure).length) {
            return FeedHelpers.getDataSetStructure(dataSet.structure);
        }
        return [];
    };

    /**
     * Create a dataSetStructure used for display from the given structure of a dataset.
     * @param {object} structure
     * @returns
     */
    static getDataSetStructure = (structure) => {
        const newStructure = [];
        Object.keys(structure).forEach((key) => {
            newStructure.push({
                key,
                title: key,
                type: 'text',
                interfaceSetup: {}
            });
        });
        return newStructure;
    };

    /**
     * Determine an list of field names for which filtering makes no sense.
     * @returns array with field names excluded for filtering
     */
    static getFilterFieldExclusions = () => {
        const orgFields = ['title', 'subtitle', 'image', 'url'];
        const idFields = ['id', '_id', 'guid', 'uuid', 'gtin'];
        const fieldExclusions = [...orgFields, ...idFields];

        orgFields.forEach((field) => {
            fieldExclusions.push(...FeedHelpers.mapOptionValues[field]);
        });

        return fieldExclusions;
    };

    /**
     * Download aan export from a finished task id and update the Redux administration of pending exports.
     * @param {integer} operationId
     */
    static getExport = (operationId) => {
        FeedRequest.get(`task/${operationId}`).then((data) => {
            if (data.data && data.data.downloadUrl) {
                const { exportTasks } = ComponentStore.get('FeedManager');
                const exportIndex = exportTasks.findIndex((task) => task.operationId === operationId);

                if (exportIndex >= 0) {
                    const fileName = exportTasks[exportIndex].fileName;
                    LWFiles.downloadFileFromUrl(data.data.downloadUrl, fileName, () => {
                        exportTasks.splice(exportIndex, 1);
                        ComponentStore.setData('FeedManager', { exportTasks });
                    });
                } else {
                    console.error('Export not found');
                }
            }
        });
    };

    /**
     * Get the status of a feed update operation.
     * @param {number} status
     * @param {object} results
     * @returns {string} status of the operation
     */
    static getOperationStatus = (status, results) => {
        if (status === 0) return 'processing';
        if (status === 1) return 'processing';
        if (status === 2 && results && results.errorType) return 'failed';
        if (status === 2 && results) return 'done';
        return 'failed';
    };

    /**
     * Determine the right label for the mutationType
     * @param {number} mutationType
     * @returns {string} label
     */
    static getMutationTypeLabel = (mutationType) => {
        switch (mutationType) {
            case 0:
                return Translation.get('activity.labels.created', 'feed-management', { count: 1 });
            case 1:
                return Translation.get('activity.labels.updated', 'feed-management', { count: 1 });
            case 2:
                return Translation.get('activity.labels.deleted', 'feed-management', { count: 1 });
            default:
                return '';
        }
    };

    /**
     * Parse an item item for display
     * @param {object} item
     * @returns {object} parsed item
     */
    static getParsedItem = (item) => {
        const parsedItem = { ...item };

        // Parse objects with the key 'result' or 'enclosure'
        // This means that it splits the result of the object in seperate items (e.g. 'result' is an object with multiple keys under it)
        if (parsedItem && parsedItem.data) {
            Object.keys(item.data).forEach((key) => {
                if ((key === 'result' || key === 'enclosure') && typeof item.data[key] === 'object') {
                    Object.keys(item.data[key]).forEach((subKey) => {
                        parsedItem.data[`${key}_${subKey}`] = item.data[key][subKey];
                    });
                    delete parsedItem.data[key];
                }
            });
        }
        delete parsedItem.mutations;

        return parsedItem;
    };
}

export default FeedHelpers;
