import { useSelector } from 'react-redux';
import { isEqual, get } from 'lodash';
import Store from 'types/store.type';

export interface ComponentStoreFields {
    [key: string]: string | ComponentStoreFields;
}
export interface ComponentStoreAdditionalFields {
    language?: string;
    editor?: unknown;
}

type ReturnType<Type> = Type & ComponentStoreAdditionalFields;

export type ComponentStoreNames = keyof Store['componentStore'];

/**
 * Gets the store of the component with the useSelector hook from Redux.
 * @template Type - The type of the component store.
 * @param componentName - The name of the component.
 * @typedef options - Optional options object.
 * @property fields - Which fields from the given comoponentName.
 * @property additionalFields - Which additional fields to be included. 'language' and 'editor' are supported.
 * @returns The component store with optional additional fields.
 */
const useComponentStore = <Type>(
    componentName: ComponentStoreNames,
    options?: { fields?: ComponentStoreFields; additionalFields?: string[] }
): ReturnType<Type> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useSelector((state: Store) => {
        const data = state.componentStore[componentName];
        const additionalFields: {
            language?: Store['editor']['language'];
            editor?: Store['editor'];
        } = {};

        // Add additional fields
        if (options?.additionalFields?.includes('language')) {
            additionalFields.language = state.editor.language;
        }
        if (options?.additionalFields?.includes('editor')) {
            additionalFields.editor = state.editor;
        }

        if (!options?.fields || !Object.keys(options?.fields).length) {
            return { ...data, ...additionalFields } as unknown as ReturnType<Type>;
        }

        let values = {
            ...additionalFields
        } as ReturnType<Type>;

        /**
         * Gets the values from the given fields.
         * @param fields - The fields to get the values from.
         * @returns The values of the given fields.
         */
        const getValues = (fields: ComponentStoreFields): { [key: string]: Type } | undefined => {
            if (!fields) return;

            const currentValue = {};

            Object.keys(fields).forEach((fieldKey) => {
                if (!fields?.[fieldKey]) return;
                if (typeof fields[fieldKey] === 'object') {
                    currentValue[fieldKey] = getValues(fields[fieldKey] as ComponentStoreFields);
                } else {
                    const value = get(data, fields[fieldKey]);
                    currentValue[fieldKey] = value;
                }
            });

            return currentValue;
        };

        values = { ...getValues(options?.fields), ...values };

        return values;
    }, isEqual);
};

export default useComponentStore;
