import '../styles/main.scss';

import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import Icon from 'components/ui-components-v2/Icon';
import Link from 'components/ui-components-v2/Link';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import { MediaInput } from 'components/template-designer/components/ui-components/media-input';
import File from './file.js';
import UploadCloud from '../../UploadCloud';

/**
 * Class FileUpload
 * The component to upload files to the backend.
 */
export class FileUpload extends React.Component {
    static propTypes = {
        fileType: PropTypes.oneOfType([
            PropTypes.oneOf(['image', 'jpg', 'png', 'imagegif', 'video', 'audio', 'pdf', 'zip', 'document', 'txt', 'font', 'all']),
            PropTypes.arrayOf(PropTypes.string)
        ]),
        fileIcon: PropTypes.string,
        previewType: PropTypes.oneOf(['file', 'image', 'video', 'none']),
        onUploadingStart: PropTypes.func,
        onDropRejected: PropTypes.func,
        onUploadComplete: PropTypes.func,
        onRemoveItem: PropTypes.func,
        multiple: PropTypes.bool,
        maxFileSize: PropTypes.number,
        noDragEventsBubbling: PropTypes.bool,
        autoOpen: PropTypes.bool,
        display: PropTypes.oneOf(['dropzone', 'inline', 'template-designer', 'bricks', 'square']),
        value: PropTypes.array,
        dark: PropTypes.bool,
        onChangeAcceptedList: PropTypes.func,
        onActionClick: PropTypes.func,
        classes: PropTypes.object,
        hidePending: PropTypes.bool,
        children: PropTypes.node,
        disabled: PropTypes.bool,
        dataCyPrefix: PropTypes.string
    };

    static defaultProps = {
        multiple: true,
        value: [],
        name: Math.random().toString(36).substr(2),
        fileType: 'image',
        previewType: 'none',
        maxFileSize: Infinity,
        display: 'dropzone',
        size: 'regular',
        noDragEventsBubbling: true,
        autoOpen: false,
        dark: false
    };

    constructor(props) {
        super(props);
        const { isHovered, value, multiple } = props;

        this.pending = [];

        // Set value
        if (value && !Array.isArray(value)) {
            this.state = { value: [value], pending: [], multiple, isHovered };
        } else if (value && Array.isArray(value)) {
            this.state = { value: value, pending: [], multiple, isHovered };
        } else {
            this.state = { value: [], pending: [], multiple, isHovered };
        }

        this.setAcceptData();
        this.dropzoneRef = createRef();
    }

    /* Open the file-upload screen automaticly when no files are active */
    componentDidMount() {
        if (!this.props.hasFiles && this.props.autoOpen) {
            if (this.dropzoneRef && this.dropzoneRef.current) {
                this.dropzoneRef.current.open();
            }
        }
    }

    setAcceptData = () => {
        const { fileType } = this.props;

        let typeLabel = fileType;
        let accept = [];
        // Set the correct mime types
        if (fileType instanceof Array) {
            typeLabel = fileType.join(', ');
            accept = fileType.map((x) => {
                if (x === 'video') {
                    return 'video/*';
                } else if (x === 'audio') {
                    return 'audio/*';
                } else if (x === 'image') {
                    return 'image/*';
                } else if (x === 'pdf') {
                    return 'application/pdf';
                } else if (x === 'font') {
                    return 'font/*';
                } else {
                    return `.${x}`;
                }
            });
            accept = accept.join(',');
        } else if (fileType === 'video') {
            accept.push('video/*');
        } else if (fileType === 'audio') {
            accept.push('audio/*');
        } else if (fileType === 'pdf') {
            accept.push('application/pdf');
        } else if (fileType === 'font') {
            accept.push('.otf');
            accept.push('.ttf');
            accept.push('.woff');
            accept.push('.woff2');
        } else if (fileType === 'zip') {
            accept.push('application/zip');
            accept.push('application/octet-stream');
            accept.push('application/x-zip-compressed');
            accept.push('multipart/x-zip');
            accept.push('application/x-compressed');
        } else if (fileType === 'image') {
            accept.push('image/png');
            accept.push('image/jpg');
            accept.push('image/jpeg');
            accept.push('image/svg+xml');
        } else if (fileType === 'imagegif') {
            typeLabel = 'png, jpg, gif';
            accept.push('image/png');
            accept.push('image/jpg');
            accept.push('image/jpeg');
            accept.push('image/gif');
        } else if (fileType === 'png') {
            accept.push('image/png');
        } else if (fileType === 'jpg') {
            accept.push('image/jpg');
            accept.push('image/jpeg');
        } else if (fileType === 'json') {
            accept.push('application/json');
        } else if (fileType === 'document') {
            accept.push('image/png');
            accept.push('image/jpg');
            accept.push('image/jpeg');
            accept.push('image/svg+xml');
            accept.push('application/zip');
            accept.push('application/octet-stream');
            accept.push('application/x-zip-compressed');
            accept.push('multipart/x-zip');
            accept.push('application/x-compressed');
            accept.push('application/msword');
            accept.push('application/vnd.openxmlformats');
            accept.push('officedocument.wordprocessingml.document');
            accept.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            accept.push('application/vnd.ms-excel');
            accept.push('application/vnd.ms-powerpoint');
            accept.push('application/vnd.openxmlformats-officedocument.presentationml.presentation');
        } else if (fileType === 'all') {
            accept = null;
            typeLabel = 'files';
        } else {
            accept.push('image/*');
        }

        this.accept = accept;
        this.typeLabel = typeLabel;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { value } = nextProps;

        if (value && !Array.isArray(value)) {
            return {
                ...prevState,
                value: [value]
            };
        } else if (value && Array.isArray(value)) {
            return {
                ...prevState,
                value: value
            };
        } else {
            return {
                ...prevState,
                value: []
            };
        }
    }

    /**
     * Get list of files when accepted and start upload
     */
    onDropAccepted = (list) => {
        const { maxFileSize } = this.props;
        const acceptedList = [];

        // Check file size of dropped files
        Array.from(list).forEach((item) => {
            if (maxFileSize && item.size > maxFileSize * 1024) {
                SnackbarUtils.error(Translation.get('snackbar.maximumFileSize', 'content-space', { file: item.path }));
            } else {
                acceptedList.push(item);
            }
        });

        if (acceptedList.length) {
            if (this.props.onChangeAcceptedList) {
                this.props.onChangeAcceptedList(acceptedList);
            }
            // Start uploading
            this.setState({ amountOfFiles: acceptedList.length }, () => {
                acceptedList.forEach((file) => {
                    this.upload(file);
                });
            });
        }
        this.setState({ isHovered: false }); // Reset hover state to false.
    };

    /**
     * Get list of files when rejected
     */
    onDropRejected = (list) => {
        list.forEach((f) => {
            SnackbarUtils.error(Translation.get('snackbar.rejected', 'content-space', { file: f.name }));
        });

        if (this.props.onDropRejected) {
            this.props.onDropRejected(list);
        }
    };

    /**
     * Upload file
     * @param {object} file The file object
     * @param {string} fileName The filename of the file.
     */
    upload = (file, fileName = null) => {
        let fileData;

        if (file.type.startsWith('video')) {
            fileData = { file, name: this.props.name, fileName, fileType: 'video' };
        } else if (file.type.startsWith('audio')) {
            fileData = { file, name: this.props.name, fileName, fileType: 'audio' };
        } else {
            fileData = { file, name: this.props.name, fileName };
        }

        // Select the correct storage
        const transfer = new UploadCloud(fileData);

        // Set up events
        transfer.on('error', (data) => this.uploadState('error', data));
        transfer.on('start', (data) => this.uploadState('start', data));
        transfer.on('uploadProgress', (data) => this.uploadState('uploadProgress', data));
        transfer.on('processEnd', (data) => this.uploadState('processEnd', data));
        transfer.start();
    };

    /**
     * Callback when upload state changed
     * @param {*} status
     * @param {*} data
     */
    uploadState(status, data) {
        let url = data.url;
        if (data.videoUrl) {
            url = data.videoUrl;
        } else if (data.lwsPath) {
            url = data.lwsPath;
        }

        let title = '';
        let extension = '';
        if (data.fileName) {
            title = data.fileName.split('.').slice(0, -1).join('.');
        }
        if (data.fileName) {
            extension = data.fileName.split('.').pop().toLowerCase();
        }

        const newData = {
            thumbnail: data.fileType === 'image' && data.base64 ? data.base64 : undefined,
            id: data.id,
            url: url,
            fileName: data.fileName,
            fileType: data.fileType,
            extension: extension,
            size: data.fileSize,
            type: data.fileType,
            progress: data.progress,
            title: title,
            status: status === 'processEnd' ? 'success' : 'pending',
            stillUrl: data.stillUrl
        };

        if (!newData.thumbnail) {
            delete newData.thumbnail;
        }

        // Start the upload
        if (status === 'start') {
            // add to pending list
            this.pending = [newData, ...this.pending];
            this.setState({ pending: this.pending });
            this.props.onUploadingStart && this.props.onUploadingStart();
        }
        // Upload has progress, update it
        else if (status === 'uploadProgress') {
            // Merge existing item and new data
            this.pending = this.pending.map((item) => {
                if (item.id === newData.id) {
                    return { ...item, ...newData };
                }
                return item;
            });

            this.setState({ pending: this.pending });
        }
        // Upload complete, process
        else if (status === 'processEnd') {
            // Remove from pending
            this.pending = this.pending.filter((item) => {
                if (item.id === newData.id) {
                    return false;
                }
                return true;
            });

            this.setState({ pending: this.pending });
            this.props.onUploadComplete([newData], this.pending);
        }
    }

    clear = () => {};

    onDragEnter = (event) => {
        const items = event.dataTransfer.items;
        const types = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.kind === 'file') {
                const fileType = item.type || 'unknown';
                types.push(fileType);
            }
        }

        this.setState({ isHovered: true }, () => {
            if (this.props.onDragEnter) {
                this.props.onDragEnter(types);
            }
        });
    };

    onDragLeave = () => {
        this.setState({ isHovered: false }, () => {
            if (this.props.onDragLeave) {
                this.props.onDragLeave();
            }
        });
    };

    renderUploadField = (getRootProps, getInputProps) => {
        const { isHovered, pending } = this.state;
        const { size = 'regular', onRemoveItem, value, height, fileType, fileIcon, display = 'dropzone', onActionClick, children } = this.props;

        if (children) {
            return (
                <div {...getRootProps()} className="file-upload__child-container">
                    {children({
                        onClickOpen: () => {
                            if (this.dropzoneRef.current) {
                                this.dropzoneRef.current.open();
                            }
                        },
                        value
                    })}
                    <input {...getInputProps()} />
                </div>
            );
        }

        if (display === 'template-designer') {
            return (
                <div {...getRootProps()} data-cy="templateDesigner-dropzone">
                    <input {...getInputProps()} />
                    <MediaInput
                        fileType={fileType}
                        fileIcon={fileIcon}
                        value={value && value[0]}
                        onClickOpen={() => {
                            if (this.dropzoneRef.current) {
                                this.dropzoneRef.current.open();
                            }
                        }}
                        onClickRemove={() => onRemoveItem(0)}
                        showRemoveButton={value && value[0]}
                        dataCyPrefix={this.props.dataCyPrefix}
                    />
                </div>
            );
        }

        if (display === 'bricks') {
            return (
                <div {...getRootProps()} className={classNames('file-upload__bricks__wrapper', isHovered ? 'file-upload__square-active' : '')}>
                    <div className="file-upload__bricks__wrapper__details">
                        {!isHovered && (
                            <EmptyState
                                illustration={<Illustration illustration="upload" />}
                                primaryText={Translation.get('labels.dropYourFile', 'common')}
                                secondaryText={Translation.get('labels.hereYouCanUploadYourAsset', 'common')}
                                primaryButton={onActionClick && { label: Translation.get('orSelectFromLibrary', 'bricks'), onClick: onActionClick }}
                            />
                        )}
                        {isHovered && (
                            <EmptyState
                                className="file-upload__bricks__wrapper__details__empty-state"
                                illustration={<Illustration illustration="upload" />}
                                primaryText={Translation.get('dropItLikeItsHot', 'bricks')}
                            />
                        )}
                    </div>
                    <input {...getInputProps()} />
                </div>
            );
        }

        if (display === 'square') {
            return (
                <div {...getRootProps()} className={classNames('file-upload__square', isHovered ? 'file-upload__square-active' : '')}>
                    <div className={classNames('file-upload__square__icon', isHovered ? 'file-upload__square-active__icon' : '')}>
                        <Icon>upload</Icon>
                    </div>
                    <input {...getInputProps()} />
                </div>
            );
        }

        // Get the height of the component
        let heightScale = 'auto';
        if (height) {
            heightScale = height + 'px';
        }
        if (pending && pending.length > 0) {
            heightScale = height - 100 + 'px';
        }

        // Base icon on type
        let icon = 'add';
        if (fileType === 'image' || fileType === 'imagegif') {
            icon = 'add_photo_alternate';
        }
        if (fileType === 'video') {
            icon = 'video_library';
        }
        if (fileType === 'pdf') {
            icon = 'picture_as_pdf';
        }
        if (fileType === 'audio') {
            icon = 'graphic_eq';
        }
        if (fileType === 'zip') {
            icon = 'perm_media';
        }

        if (display === 'dropzone') {
            return (
                <div
                    {...getRootProps()}
                    className={`file-upload__box ${isHovered ? 'file-upload__box--is-hovered' : ''} file-upload__box--${size}`}
                    style={{ height: heightScale }}>
                    <div className="file-upload__box__center">
                        <div className="file-upload__box__icon-holder">
                            <Icon size="large">{icon}</Icon>
                        </div>
                        <div className="file-upload__box__text">
                            Drop or browse <span>{this.typeLabel}</span>
                        </div>
                    </div>
                    <input {...getInputProps()} />
                </div>
            );
        }

        return (
            <div {...getRootProps()} className="file-upload__box__view-line">
                <Link variant="body2">Add file</Link>
                <input {...getInputProps()} />
            </div>
        );
    };

    render() {
        const { pending } = this.state;
        const { noDragEventsBubbling, dark, display, classes, hidePending, children, disabled } = this.props;

        return (
            <div className={classNames('file-upload', classes?.root)}>
                <Dropzone
                    noClick={display === 'template-designer' || !!children}
                    noKeyboard={display === 'template-designer' || !!children}
                    ref={this.dropzoneRef}
                    disabled={disabled}
                    accept={this.accept}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    onDropAccepted={this.onDropAccepted}
                    onDropRejected={this.onDropRejected}
                    noDragEventsBubbling={noDragEventsBubbling}
                    multiple={this.state.multiple}>
                    {({ getRootProps, getInputProps }) => this.renderUploadField(getRootProps, getInputProps)}
                </Dropzone>
                {!hidePending && pending.length > 0 && (
                    <div
                        className={classNames(
                            'file-upload__list',
                            'file-upload__list--pending',
                            {
                                'file-upload__list--dark': dark
                            },
                            classes?.fileBox
                        )}>
                        {pending.map((file, i) => (
                            <File key={i + '-' + file.id} {...file} showOnlyProgress={display === 'template-designer'} />
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default FileUpload;
