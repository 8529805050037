import { get, set } from 'lodash';

class FeedSelector {
    /**
     * Create a mapped data object from a selected feed item.
     * @param {object} mapping
     * @param {object} item
     * @param {string} language
     * @param {array} useLanguage
     * @returns mapped data item
     */
    static getMappedData = (mapping, item, language, useLanguage, useObject) => {
        let mappedData = false;
        const missingData = [];

        if (mapping && Object.keys(mapping).length > 0) {
            mappedData = {};
            Object.keys(mapping).forEach((mappingKey) => {
                let value;

                try {
                    value = get(item.data, mapping[mappingKey]);
                } catch (err) {
                    console.error('Error onMutation', err);
                }

                if (!value && value !== false) missingData.push(mapping[mappingKey]);

                if (useLanguage.includes(mappingKey) && language) {
                    const translatedValue = { multilanguage: true };
                    translatedValue[language] = { value };
                    if (useObject) {
                        if (!mappedData) {
                            mappedData = {};
                        }
                        set(mappedData, mappingKey, translatedValue);
                    } else {
                        mappedData[mappingKey] = translatedValue;
                    }
                } else {
                    if (useObject) {
                        if (!mappedData) {
                            mappedData = {};
                        }
                        set(mappedData, mappingKey, value);
                    } else {
                        mappedData[mappingKey] = value;
                    }
                }
            });

            // Also save the original item (for example, to show the DataCard)
            mappedData.originalRecord = item;

            // Always have the feed item _id in the root of the object
            mappedData._id = item._id;
        }

        return { mappedData, missingData };
    };

    /**
     * get the item data from a previously selected value.
     * @param {object} value
     * @returns item data
     */
    static getItemData = (value) => {
        if (value && value.originalRecord && value.originalRecord.data) return value.originalRecord.data;
        else if (value && value.originalRecord) return value.originalRecord;
        else if (value && value.data) return value.data;
        else return value;
    };

    /**
     * Get the id of a previous selected item.
     * @param {object} item
     * @returns item id
     */
    static getItemId = (item) => {
        if (item.originalRecord) return item.originalRecord._id;
        else return item._id;
    };

    /**
     * Get the custom datacard mapping
     * @param {object} item
     * @param {*} propsDatacardMapping the datacardMapping received from the component props.
     */
    static getDataCardMapping = (item, propsDatacardMapping) => {
        if (propsDatacardMapping) {
            return propsDatacardMapping;
        } else if (item.originalRecord && item.originalRecord.datacardMapping) {
            return item.originalRecord.datacardMapping;
        } else if (item.datacardMapping) {
            return item.datacardMapping;
        } else {
            return null;
        }
    };
}

export default FeedSelector;
