import axios from 'axios';

/**
 * LWFiles class
 */
export default class LWFiles {
    static supportedTypes = { image: 'img', video: 'vid', audio: 'audio' };
    static pollInterval = 2000;

    /**
     *
     * @param file
     * @param _key
     * @param fileName
     * @returns {{originalFilename, 'x-amz-meta-realname', fileSize: *, displaySize: *}}
     */
    static getFileData(file, _key, fileName = false) {
        const name = !fileName ? file.name : fileName;

        return {
            _key,
            fileName: name.replace(/\s/g, '_'),
            'x-amz-meta-realname': name.replace(/\s/g, '_'),
            fileSize: LWFiles.getSize(file),
            title: name.replace(/\s/g, ' ').replace('-', ' ').replace('_', ' ').replace('.jpg', ' ').replace(',png', ' '),
            displaySize: LWFiles.getSize(file, true),
            ...LWFiles.getTypes(file)
        };
    }

    /**
     *
     * @param file
     * @param callback
     */
    static imageToBase64(file, callback) {
        if (typeof file === 'string') {
            callback(file);
            return;
        }

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const image = new Image();
                image.onload = () => {
                    callback({
                        base64: e.target.result,
                        width: image.naturalWidth,
                        height: image.naturalHeight
                    });
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    /**
     * Get video thumbnail
     * @param url
     * @returns {string}
     */
    static getVideoThumbnail(url = '') {
        return url.lastIndexOf('/') < 0 ? '' : `${url.substr(0, url.lastIndexOf('/'))}/thumbnail.jpg`;
    }

    /**
     * Check whether the url is a cideo
     * @param url
     * @returns {boolean}
     */
    static urlIsVideo(url = '') {
        if (typeof url !== 'string') {
            return false;
        }
        let extension = url.match(/\.([^\./\?]+)($|\?)/); // eslint-disable-line
        extension = extension ? `.${extension[1]}` : null;
        if (!extension) {
            return false;
        }
        return ['.mp4', '.mov', '.mpg', '.mpeg', '.wmv', '.avi'].indexOf(extension) > -1;
    }

    /**
     * Get extension of file
     * @param url
     * @returns {string} extension
     */
    static getUrlExtension(url = '') {
        if (typeof url !== 'string') {
            return false;
        }
        let extension = url.match(/\.([^\./\?]+)($|\?)/); // eslint-disable-line
        extension = extension ? `.${extension[1]}` : null;
        if (!extension) {
            return false;
        }
        return extension.toLowerCase();
    }

    /**
     * Get filename of file
     * @param url
     * @returns {string} extension
     */
    static getUrlFilename(url) {
        const ext = LWFiles.getUrlExtension(url);
        const snakeCaseName = url.substring(url.lastIndexOf('/') + 1).replace(`.${ext}`, '');
        return snakeCaseName.replace(/_/g, ' ').replace(/-/g, ' ');
    }

    /**
     *
     * @param file
     * @returns {{fileType: *|string, extension: *|string, mimeType}}
     */
    static getTypes(file) {
        if (typeof file === 'string') {
            return {
                fileType: 'image',
                extension: 'jpeg',
                mimeType: 'image/jpeg'
            };
        }
        return {
            fileType: file.type.split('/')[0],
            extension: file.type.split('/')[1],
            mimeType: file.type
        };
    }

    /**
     *
     * @param file
     * @param humanReadable
     * @returns {*}
     */
    static getSize(file, humanReadable = false) {
        return !humanReadable ? file.size : LWFiles.humanReadableSize(file.size);
    }

    /**
     * Gets the size in bytes for a base64 url
     * @param {string} base64Url Base64 URl
     */
    static getBase64Size(base64Url) {
        return Math.floor((base64Url.length - 814) / 1.37);
    }
    /**
     *
     * @param bytes
     * @param decimals (optional)
     * @returns {string}
     */
    static humanReadableSize(bytes, decimals = 0) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

        if (decimals === 0) {
            return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
        }

        return (bytes / Math.pow(1024, i)).toFixed(decimals) + ' ' + sizes[i];
    }

    /**
     *
     * @param _key
     * @param awsPath
     * @param callback
     */
    static ready({ _key, awsPath }, callback) {
        axios
            .get(awsPath)
            .then(() => callback(_key))
            .catch((error) => {
                if (error.response.status !== 403) {
                    console.error(error.response);
                }
                setTimeout(() => {
                    LWFiles.ready({ _key, awsPath }, callback);
                }, LWFiles.pollInterval);
            });
    }

    static imageToExif(file, settings) {
        const { maxWidth, maxHeight, quality = 1 } = settings;
        const type = LWFiles.getTypes(file);
        return new Promise((resolve, reject) => {
            try {
                const callback = (srcOrientation) => {
                    const reader2 = new FileReader();
                    reader2.onload = (e) => {
                        const srcBase64 = e.target.result;
                        const img = new Image();

                        img.onload = () => {
                            let width = img.width;
                            let height = img.height;
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');

                            if (maxWidth && maxHeight) {
                                if (width > height) {
                                    if (width > maxWidth) {
                                        height *= maxWidth / width;
                                        width = maxWidth;
                                    }
                                } else {
                                    if (height > maxHeight) {
                                        width *= maxHeight / height;
                                        height = maxHeight;
                                    }
                                }
                            }

                            // set proper canvas dimensions before transform & export
                            if (4 < srcOrientation && srcOrientation < 9) {
                                canvas.width = height;
                                canvas.height = width;
                            } else {
                                canvas.width = width;
                                canvas.height = height;
                            }

                            // transform context before drawing image
                            switch (srcOrientation) {
                                case 2:
                                    ctx.transform(-1, 0, 0, 1, width, 0);
                                    break;
                                case 3:
                                    ctx.transform(-1, 0, 0, -1, width, height);
                                    break;
                                case 4:
                                    ctx.transform(1, 0, 0, -1, 0, height);
                                    break;
                                case 5:
                                    ctx.transform(0, 1, 1, 0, 0, 0);
                                    break;
                                case 6:
                                    ctx.transform(0, 1, -1, 0, height, 0);
                                    break;
                                case 7:
                                    ctx.transform(0, -1, -1, 0, height, width);
                                    break;
                                case 8:
                                    ctx.transform(0, -1, 1, 0, 0, width);
                                    break;
                                default:
                                    break;
                            }

                            // draw image
                            ctx.drawImage(img, 0, 0, width, height);

                            // export base64
                            resolve(canvas.toDataURL(type.mimeType, type.extension === 'jpeg' ? quality : 0));
                        };

                        img.src = srcBase64;
                    };

                    reader2.readAsDataURL(file);
                };

                const reader = new FileReader();
                reader.onload = (e) => {
                    const view = new DataView(e.target.result);
                    if (view.getUint16(0, false) != 0xffd8) return callback(-2);

                    const length = view.byteLength;
                    let offset = 2;

                    while (offset < length) {
                        const marker = view.getUint16(offset, false);
                        offset += 2;
                        if (marker == 0xffe1) {
                            if (view.getUint32((offset += 2), false) != 0x45786966) return callback(-1);
                            const little = view.getUint16((offset += 6), false) == 0x4949;
                            offset += view.getUint32(offset + 4, little);
                            const tags = view.getUint16(offset, little);
                            offset += 2;
                            for (let i = 0; i < tags; i++)
                                if (view.getUint16(offset + i * 12, little) == 0x0112) return callback(view.getUint16(offset + i * 12 + 8, little));
                        } else if ((marker & 0xff00) != 0xff00) break;
                        else offset += view.getUint16(offset, false);
                    }
                    return callback(-1);
                };
                reader.readAsArrayBuffer(file);
            } catch (e) {
                reject(e);
            }
        });
    }

    static loadImage(url) {
        return new Promise((res, rej) => {
            const img = new Image();
            img.onerror = (error) => res(null);
            img.onload = (response) =>
                res({
                    url,
                    width: img.width,
                    height: img.height
                });
            img.src = url;
        });
    }

    static loadVideo(url) {
        return new Promise((res, rej) => {
            const video = document.createElement('video');
            video.onerror = () => res(null);
            video.onloadedmetadata = () => {
                res({
                    url,
                    width: video.videoWidth,
                    height: video.videoHeight
                });
            };
            video.src = url;
            video.load(); // Load the video to ensure the metadata is available
        });
    }

    /**
     * Offer a file available on a url as a download in the users browser.
     * Optionally add a callback function executed once the file is downloading.
     * @param {string} url
     * @param {string} name
     * @param {function} callback
     */
    static downloadFileFromUrl(url, name, callback = () => {}) {
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob'
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                callback();
            })
            .catch((error) => {
                console.log('Error when downloading file from url', error);
            });
    }

    /**
     * Offer a file available as a data string as a download in the users browser.
     * Optionally add a callback function executed once the file is downloading.
     * @param {string} data
     * @param {string} name
     * @param {function} callback
     */
    static downloadFileFromData(data, name, callback = () => {}) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        callback();
    }
}
