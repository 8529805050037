import Templates from 'components/data/Templates';
import EditorData from 'components/editor-data/EditorData';
import store from '../../../store';
import DataHelpers from '../DataHelpers';
import CustomerHelperLoader from '../CustomerHelperLoader';

export default class EmailHelpers {
    /**
     * Cleanup the string about to be injected in to the email template.
     * @param {string} value to be inserted into the email template
     * @returns
     */
    static cleanup = (value) => {
        // Check if content is wrapped in <p> tags set by the rich text editor, and if so, replace <p>'s with <br />'s
        if (typeof value === 'string' && value.substring(0, 2) === '<p') {
            value = value
                .replace(/<p[^>]*>/g, '')
                .replace(/<\/p>/g, '<br />')
                .replace(/<br \/>$/, '');
        }

        // replace &nbsp's with regular spaces since they might mess up the layout.
        if (typeof value === 'string') {
            value = value.replace(/&nbsp;/g, ' ');
        }

        return value;
    };

    /**
     * Create an array of email blocks representing the output of the content of an email.
     * @param {*} dataModel
     * @param {*} settingsModel
     * @param {*} emailBlocks
     * @param {*} value
     * @returns
     */

    static outputEmail = (dataModel, settingsModel, value) => {
        const emailBlocks = Templates.get('emailBlock');

        // Get variables
        let campaign = store.getState().editor.data; // eslint-disable-line
        let editor = store.getState().editor; // eslint-disable-line
        let language = editor.language; // eslint-disable-line
        let market = editor.market; // eslint-disable-line
        let origin = editor.origin; // eslint-disable-line
        let customerHelper = new CustomerHelperLoader.helper(); // eslint-disable-line
        let testVariant = 'GEN'; // eslint-disable-line
        let input = value ? value : []; // eslint-disable-line

        // Fix for remove issue
        if (!input.map && input) {
            input = Object.keys(input).map((key) => input[key]);
        }

        let blockNr = 1;
        return input.map((x) => {
            // Get data of the active block
            let component = emailBlocks[x.identifier];
            if (!component) {
                component = {};
            }
            const settings = component.settings ? component.settings : {};

            // Get data (from subsets)
            const subsetData = EditorData.getValueFromModel(dataModel + '.' + x.uuid + '.subsetData');
            let subsetActive = EditorData.getValueFromModel(dataModel + '.' + x.uuid + '.subsetActive');
            let block = {};

            if (!subsetActive) {
                subsetActive = 'main';
            }
            if (subsetData) {
                block = EditorData.getValueFromModel(dataModel + '.' + x.uuid + '.subsetData.' + subsetActive);
            } else {
                block = EditorData.getValueFromModel(dataModel + '.' + x.uuid);
            }

            /* eslint-disable */
            // Get item
            function get(path) {
                path = path.replace(/\[language\]/g, '.' + language);
                const value = DataHelpers.getValue(block, path, '');
                return EmailHelpers.cleanup(value);
            }

            // Get general copy
            function getGeneralCopy(path) {
                const editor = store.getState().editor;
                const language = editor.language;

                const value = EditorData.getValueFromModel(path + '.' + language + '.value');
                return value;
            }

            // Eval HTML
            let isEditor = true;
            let html = component.html;
            try {
                html = eval('`' + component.html + '`');
            } catch (e) {
                console.log('Error rendering email block ', e);
            }
            /* eslint-enable */

            // Get pinnedToTop
            const pinnedToTop = DataHelpers.getValue(block, 'pinnedToTop') ? true : false;
            const pinnedToBottom = DataHelpers.getValue(block, 'pinnedToBottom') ? true : false;
            const abTestGroups = EditorData.getValueFromModel(dataModel + '.' + x.uuid + '.abTestGroups')
                ? EditorData.getValueFromModel(dataModel + '.' + x.uuid + '.abTestGroups')
                : false;
            blockNr++;

            // Return array
            return {
                uuid: x.uuid,
                identifier: component.identifier,
                workingTitle: component.title,
                html,
                settings,
                pinnedToTop,
                pinnedToBottom,
                abTestGroups,
                blockNr
            };
        });
    };

    /**
     * Get the correct email base to use from the templates.
     * @param {array} emailBases as retreived from the templates
     * @param {string} groupKey current group key.
     * @returns the email base to use in the current email.
     */
    static getEmailBase = (groupKey) => {
        const emailBases = Templates.get('emailBase');
        let emailBase = {};
        if (emailBases) {
            Object.keys(emailBases).forEach((key) => {
                const item = emailBases[key];
                if (!groupKey || item.groupKey === groupKey) {
                    emailBase = item;
                }
            });
        }
        return emailBase;
    };
}
