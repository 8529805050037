import axios from 'axios';
import ComponentStore from '../ComponentStore';
import CreativeManagementHelpers from './index';

/**
 * Set initial request data
 */

const CreativeManagementRequest = axios.create({
    baseURL: process.env.APP_ASSET_LIBRARY_URL,
    timeout: process.env.APP_API_TIMOUT,
    withCredentials: false
});
CreativeManagementRequest.interceptors.request.use(
    async (config) => {
        let token = '';
        if (ComponentStore.get('CreativeManagement')) {
            token = ComponentStore.get('CreativeManagement').token;
        } else {
            token = await CreativeManagementHelpers.getToken();
        }

        if (token) {
            config.headers.Authorization = token;
        }

        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

export default CreativeManagementRequest;
