import PropTypes from 'prop-types';
import React from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import Typography from 'components/ui-components-v2/Typography';
import fileHelper from './../../Files';
import iconCheckmark from './../images/checkmark.svg';
import iconRemove from './../images/close.svg';
import '../styles/main.scss';

/**
 * Class FieldFile
 * Displays a file preview of while uploading
 */
class FieldFile extends React.Component {
    static propTypes = {
        value: PropTypes.shape({
            filename: PropTypes.string,
            title: PropTypes.string
        }),
        progress: PropTypes.number,
        dark: PropTypes.bool,
        onComplete: PropTypes.func,
        onRemove: PropTypes.func,
        showOnlyProgress: PropTypes.bool
    };

    state = {
        name: Math.random().toString(36).substr(2),
        isHovered: false,
        showOnlyProgress: false
    };

    render() {
        const { thumbnail, title, status, progress, size, onRemove, extension, showOnlyProgress } = this.props;

        let fileIconSpecs = {};
        if (defaultStyles[extension]) {
            fileIconSpecs = defaultStyles[extension];
        }

        return (
            <div className="file-upload__item">
                {!showOnlyProgress && (
                    <div className="file-upload__item__content">
                        {thumbnail && (
                            <div className="file-upload__item__thumbnail">
                                <div className="file-upload__item__image" style={{ backgroundImage: `url(${thumbnail})` }} />
                            </div>
                        )}
                        {!thumbnail && (
                            <div className="file-upload__item__extension">
                                <FileIcon extension={extension} {...fileIconSpecs} />
                            </div>
                        )}

                        <div className="file-upload__item__copy">
                            <Typography variant="h5">{title}</Typography>
                            <Typography variant="caption" color="textSecondary">
                                {status === 'pending' ? `Uploading | ${progress}% of ${fileHelper.humanReadableSize(size)}` : 'Upload completed'}
                            </Typography>
                        </div>
                        <div className="file-upload__item__actions">
                            {status === 'pending' && progress === 100 && (
                                <div className="file-upload__item__actions__action">
                                    <img className="icon" src={iconCheckmark} alt="" />
                                </div>
                            )}
                            {status !== 'pending' && (
                                <div className="file-upload__item__actions__action" onClick={() => onRemove()}>
                                    <img className="icon" src={iconRemove} alt="" />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {progress > 0 && (
                    <div className="file-upload__item__progress">
                        <div className="file-upload__item__progress__fill" style={{ width: `${progress}%` }} />
                    </div>
                )}
            </div>
        );
    }
}

export default FieldFile;
