import { v4 as uuidv4 } from 'uuid';
import store from 'src/store';
import { merge } from 'lodash';
import cloneDeep from 'helpers/cloneDeep';
import EditorData from 'components/editor-data/EditorData';
import EditorChanged from 'components/editor-data/EditorData/editor-changed';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import ComponentStore from 'components/data/ComponentStore';
import Request from 'components/data/Request';
import Setup from 'components/data/Setup';
import Translation from 'components/data/Translation';
import Templates from 'components/data/Templates';
import DataHelpers from 'components/data/DataHelpers';
import ViewState from 'components/data/ViewState';
import CreativeManagementRequest from './request';
import getTemplateTypes from '../../../controllers/Templates/data/templateTypes';

class CreativeManagementHelpers {
    // The creative types that are created on a html template.
    static htmlTemplateBasedCreatives = [
        'dynamicImage',
        'displayAd',
        'displayAdDesigned',
        'dynamicImageDesigned',
        'dynamicVideoDesigned',
        'dynamicPDF',
        'dynamicPDFDesigned',
        'socialChannelItem'
    ];

    /**
     * Perform API call to get an access token to the content space API
     */
    static getToken = async () => {
        const data = await Request.post('contentspace/getToken', {});

        if (data.success === 1) {
            return data.data.token;
        }

        return null;
    };

    /**
     * Initialize the ComponentStore for CreativeManagement.
     */
    static init = async (preFilters) => {
        if (!ComponentStore.get('CreativeManagement')) {
            const token = await this.getToken();

            // Get the templateType names once and store them in Redux.
            const templateLookup = {};
            const templateTypeArray = getTemplateTypes;
            templateTypeArray.forEach((templateType) => (templateLookup[templateType.id] = templateType));

            ComponentStore.setData('CreativeManagement', {
                creativesLoadError: false,
                creativesList: [],
                creativesLoaded: false,
                creativesSorting: ViewState.get('creativeManagement', 'sorting', 'local') || {},
                searchTerm: ViewState.get('creativeManagement', 'searchTerm', 'local') || '',
                filters: ViewState.get('creativeManagement', 'filters', 'local') || {},
                templateLookup,
                token
            });
        }

        this.setFilters(preFilters);
    };

    static setFilters = (preFilters = {}) => {
        // Set the initial filtersetup
        const filterSetup = [];
        if (!preFilters['templateIdentifiers']) {
            filterSetup.unshift({
                name: 'templateIdentifiers',
                label: Translation.get('filters.labels.templateIdentifier', 'creative-management'),
                type: 'selectMultiple',
                options: []
            });
        }
        if (!preFilters['templateTypes']) {
            filterSetup.unshift({
                name: 'templateTypes',
                label: Translation.get('filters.labels.creativeType', 'creative-management'),
                type: 'selectMultiple',
                options: []
            });
        }
        if (Setup.hasModule('multiBrand'))
            filterSetup.push({
                name: 'brands',
                label: Translation.get('campaign.brands', 'common'),
                type: 'selectMultiple',
                options: []
            });
        if (Setup.hasModule('multiMarket'))
            filterSetup.push({
                name: 'markets',
                label: Translation.get('campaign.markets', 'common'),
                type: 'selectMultiple',
                options: []
            });
        if (Setup.hasModule('multiDepartment'))
            filterSetup.push({
                name: 'departments',
                label: Translation.get('campaign.department', 'common'),
                type: 'selectMultiple',
                options: []
            });

        ComponentStore.setData('CreativeManagement', {
            filterSetup,
            filters: preFilters,
            searchTerm: ''
        });
    };

    /**
     * For use in the CreativeEditor we need creatives to have a uuid that can serve a s a valid JSON key.
     * @param {string} referenceId
     * @returns string that can serve as a uuid in CreativeEditor.
     */
    static createUuid = (referenceId) => {
        return `c_${referenceId.replaceAll('-', '_')}`;
    };

    /**
     *
     * @param {Array} assets
     * @param {Array} templateFormats
     * @returns new list of assets filtered on formats that are inside template formats
     */
    static filterAssetFormats = (assets, templateFormats) => {
        const newAssets = assets.filter((asset) => {
            return asset?.creativeData?.assetSetup?.settings?.formats.some((format) => {
                //If the asset has one or more formats in common with the templateFormats list
                //it goes through
                return templateFormats.includes(format.format);
            });
        });

        return newAssets;
    };

    /**
     *
     * @param {String} filterType
     * @returns Translated filterType to asset propperty they are not quiet the same
     */
    static getAssetProperty = (filterType) => {
        switch (filterType) {
            case 'templateIdentifiers':
                return 'templateIdentifier';
            case 'templateTypes':
                return 'templateType';
            default:
                return filterType;
        }
    };

    /**
     *
     * @param {Object} relevant
     * @param {Array} assets
     * @returns New object of relevant filters filtered on assets that were filtered away previously
     */
    static filterRelevantAssets = (relevant, assets) => {
        const newRelevant = {};
        for (const [filterType, filters] of Object.entries(relevant)) {
            newRelevant[filterType] = filters.filter((filter) => {
                //Only add filters where one or more assets has the filter value in the currently filtered property
                return assets.some((asset) => {
                    //e.g. asset['brands'].includes('byCape')
                    //e.g. asset['property'].includes('filter value')
                    return asset[this.getAssetProperty(filterType)]?.includes(filter._id);
                });
            });
        }

        return newRelevant;
    };

    /**
     * Get the creatives from the APi and store them in Redux.
     * If refresh is true, we're starting a new list. If not we're continuing on a paginated list.
     * @param {bool} refresh
     */
    static loadCreatives = (refresh, templateFormats = false) => {
        refresh && ComponentStore.setData('CreativeManagement', { creativesLoaded: false });

        const { creativesList, next, filters, searchTerm, creativesSorting } = ComponentStore.get('CreativeManagement');
        const setResults = (data) => {
            let { assets, relevant } = data;
            const { next } = data;

            //If template formats is given on load filter on it after getting data
            if (templateFormats) {
                assets = this.filterAssetFormats(assets, templateFormats);
                relevant = this.filterRelevantAssets(relevant, assets);
            }

            const hasMore = assets.length === 25;

            const enrichedAssets = assets.map((asset) => ({
                ...asset,
                uuid: this.createUuid(asset.referenceId) // We want to have uuid's that can be a valid JSON key.
            }));

            const list = refresh ? enrichedAssets : [...creativesList, ...enrichedAssets];
            const payload = {
                creativesList: list,
                next,
                hasMore,
                creativesLoaded: templateFormats && hasMore ? false : true
            };

            if (relevant) payload.filterSetup = this.mergeFilters(relevant);
            ComponentStore.setData('CreativeManagement', payload);

            //We need to get the full list for the front-end filtering to happen propperly
            //So if we want to filter on template formats and there are still more templates load more
            if (templateFormats && hasMore) {
                this.loadCreatives(false, templateFormats);
            }
        };

        // Add the sorting to the params for the call.
        const params = { ...creativesSorting };

        // If we're not refreshing, add the next to the params for the call.
        if (!refresh) params.next = next;

        // Set up the filter object for the call.
        if (searchTerm && searchTerm.length) {
            filters.query = searchTerm;
        } else {
            delete filters.query;
        }

        // If we have filters do a search, otherwise it's a list call.
        if (filters && Object.keys(filters).length) {
            // We're filtering.
            CreativeManagementRequest.post('assets/creatives/search', { filters, ...params }).then((response) => {
                setResults(response.data);
            });
        } else {
            // We're requesting the unfiltered list.
            CreativeManagementRequest.get('assets/creatives', { params: { ...params } }).then((response) => {
                // To update the filtercount, request the filters.
                CreativeManagementRequest.get('statistics/popular/creative-filters').then((filtersResponse) => {
                    setResults({ ...response.data, relevant: filtersResponse.data });
                });
            });
        }
    };

    /**
     * Update the stored filtersetup with the current filter counts received from the API.
     * @param {array} currentFilters
     * @returns
     */
    static mergeFilters = (currentFilters) => {
        const { filterSetup } = ComponentStore.get('CreativeManagement');
        const newFilterSetup = filterSetup.map((filter) => {
            if (currentFilters[filter.name]) {
                return {
                    ...filter,
                    options: this.getFilterOptionLabels(currentFilters[filter.name], filter.name)
                };
            }
            return filter;
        });
        return newFilterSetup;
    };

    static getFilterOptionLabels = (options, filterName) => {
        const { templateLookup } = ComponentStore.get('CreativeManagement');
        const sourceBrands = Setup.get('brands');
        const sourceMarkets = Setup.get('markets');
        const sourceDepartments = Setup.get('departments');
        const newOptions = options
            .filter((option) => typeof option._id === 'string')
            .map((option) => {
                const value = (() => {
                    if (filterName === 'templateTypes') {
                        const thisTemplate = templateLookup[option._id];
                        return thisTemplate ? thisTemplate.title : option._id;
                    }
                    if (filterName === 'templateIdentifiers') {
                        return this.findTemplateName(option._id);
                    }
                    if (filterName === 'brands') {
                        return sourceBrands[option._id] || option._id;
                    }
                    if (filterName === 'markets') {
                        return sourceMarkets[option._id] || option._id;
                    }
                    if (filterName === 'departments') {
                        return sourceDepartments[option._id] || option._id;
                    }
                    return option._id;
                })();
                return { key: option._id, value, count: option.count };
            });
        return newOptions;
    };

    /**
     * Set a filter change received by the filter chps to Redux.
     * @param {string} key
     * @param {array} value
     * @param {object} filters
     */
    static changeFilter = (key, value, filters) => {
        const newFilters = cloneDeep(filters);
        if (!value.length) {
            delete newFilters[key];
        } else {
            newFilters[key] = value;
        }
        ComponentStore.setModel('CreativeManagement', 'filters', newFilters);
    };

    /**
     * Find a template name in the templates stored in Redux.
     * @param {string} identifier
     * @returns template name
     */
    static findTemplateName = (identifier) => {
        const templates = store.getState().templates;
        if (!templates) return identifier;
        if (templates.templateList) {
            let value;
            templates.templateList.forEach((type) => {
                if (templates[type] && templates[type][identifier]) {
                    value = templates[type][identifier].title;
                }
            });
            if (value) return value;
        }
        return identifier;
    };

    /**
     * Save a creator to the API. Either create a new one, or do an update. Depending
     */
    static saveCreative = () => {
        const { uuid, subsetActive } = ComponentStore.get('CreativeEditor');
        const { creativeManagement } = EditorData.get('data');
        const { setup: currentSetup, data: currentData } = creativeManagement;
        const activeCreative = currentSetup.find((creative) => creative.uuid === uuid);

        // Get the metatdata that needs to be saved in the root of the creative..
        const metadata = {};
        if (currentData[uuid].title) {
            metadata.title = currentData[uuid].title;
        }
        if (currentData[uuid].creativeMetadata) {
            if (currentData[uuid].creativeMetadata.brands) {
                metadata.brands = currentData[uuid].creativeMetadata.brands;
            }
            if (currentData[uuid].creativeMetadata.departments) {
                metadata.departments = currentData[uuid].creativeMetadata.departments;
            }
            if (currentData[uuid].creativeMetadata.markets) {
                metadata.markets = currentData[uuid].creativeMetadata.markets;
            }
        }

        const previewData = this.getPreviewData(currentData[uuid], subsetActive);

        if (activeCreative && activeCreative.referenceId) {
            // It's is an existing creative. Do an update.

            const updatedCreative = {
                ...metadata,
                creativeData: currentData[uuid]
            };
            if (previewData) {
                updatedCreative.previewData = previewData;
            }

            CreativeManagementRequest.put(`assets/creatives/${activeCreative.referenceId}`, updatedCreative)
                .then((response) => {
                    const newCreative = {
                        uuid,
                        ...response.data
                    };
                    this.openCreative(newCreative);
                    this.replaceCreativeInManagement(newCreative);
                    EditorChanged.setChanged(false);
                    SnackbarUtils.success(Translation.get('feedback.creativeSaved', 'creative-management'));
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('feedback.creativeSaveFailed', 'creative-management'));
                    console.log(error);
                });
        } else {
            // It's is a new creative.
            const newCreative = {
                templateIdentifier: activeCreative.identifier,
                templateType: activeCreative.type,
                ...metadata,
                creativeData: currentData[uuid]
            };
            if (previewData) {
                newCreative.previewData = previewData;
            }
            CreativeManagementRequest.post('assets/creatives', newCreative)
                .then((response) => {
                    if (response.data && response.data.asset) {
                        const addedCreative = {
                            ...response.data.asset,
                            uuid // Add the uuid otherwise the Creative Editor can't find the active creative.
                        };
                        this.openCreative(addedCreative);
                        this.addCreativeToManagement(addedCreative);
                        if (ComponentStore.get('CreativeEditor')) ComponentStore.setData('CreativeEditor', { referenceId: response.data.asset.referenceId });
                    }
                    EditorChanged.setChanged(false);
                    SnackbarUtils.success(Translation.get('feedback.creativeSaved', 'creative-management'));
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('feedback.creativeAddFailed', 'creative-management'));
                    console.error(error);
                });
        }
    };

    /**
     * Get the actual subset to use based on the active subset and the available subsets.
     * @param {*} subsets Array of all the subsets
     * @param {*} subsetActive The active subset
     * @returns The actual subset to use
     */
    static getSubset = (subsets, subsetActive) => {
        if (subsets) {
            return subsets.find((subset) => subset === subsetActive) || subsets[0];
        }
        return subsetActive;
    };

    /**
     * Collect and format the data od a creative so the API can generate a preview.
     * @param {object} data
     * @param {string} subsetActive
     * @returns {object}
     */
    static getPreviewData = (data, subsetActive) => {
        let currentData = cloneDeep(data);
        if (currentData.type === 'socialChannelItem' && currentData.assetSetup?.frames?.frame1) {
            //If social channel item with frames
            const subset = this.getSubset(currentData.subsets, subsetActive);
            currentData = {
                assetSetup: currentData.assetSetup.frames.frame1,
                data: {
                    [subset]: {
                        assetData: currentData.data[subset].assetData.frames.frame1
                    }
                }
            };
        }

        if (!currentData.assetSetup) return null;
        if (!currentData.assetSetup.type) return null;
        if (!this.htmlTemplateBasedCreatives.includes(currentData.assetSetup.type)) return null;

        const type = currentData.assetSetup.type;
        const assetSetup = EditorData.getValueFromModel('assetSetup', currentData);
        const positionByLanguage = DataHelpers.getValue(currentData, 'assetSetup.settings.positionByLanguage') ? true : false;
        const uploadByLanguage = DataHelpers.getValue(currentData, 'assetSetup.settings.uploadByLanguage') ? true : false;
        const formats = DataHelpers.getValue(currentData, 'assetSetup.settings.formats');
        const bestFormat = this.getPreviewDataFormat(formats);

        const subset = this.getSubset(currentData.data.subsets, subsetActive);
        const adData = (() => {
            if (currentData?.data[subset]?.assetData) {
                // Integrate format specific settings
                if (currentData?.data[subset]?.assetData.overwrites?.[bestFormat.format]) {
                    currentData.data[subset].assetData = merge(
                        currentData.data[subset].assetData,
                        currentData.data[subset].assetData.overwrites[bestFormat.format]
                    );
                }
                return currentData?.data[subset]?.assetData;
            }
            return {};
        })();
        const url = (() => {
            if (assetSetup && assetSetup.settings && assetSetup.settings.url) {
                return assetSetup.settings.url;
            } else if (assetSetup && assetSetup.url) {
                return assetSetup.url;
            } else if (assetSetup && assetSetup.previewUrl) {
                return assetSetup.previewUrl;
            }
            return null;
        })();

        if (!bestFormat) return null;

        switch (type) {
            case 'displayAd':
            case 'dynamicImage':
            case 'dynamicPDF':
                return {
                    url,
                    width: bestFormat.width,
                    height: bestFormat.height,
                    data: {
                        ...adData,
                        format: bestFormat.format,
                        language: EditorData.get('language'),
                        editmode: 0,
                        frameNr: 1,
                        positionByLanguage,
                        uploadByLanguage
                    }
                };
            case 'displayAdDesigned':
            case 'dynamicVideoDesigned':
            case 'dynamicImageDesigned':
            case 'dynamicPDFDesigned':
                const template = Templates.get(type, assetSetup.templateIdentifier, 'templateExport');

                return {
                    url: process.env.TEMPLATES_URL,
                    width: bestFormat.width,
                    height: bestFormat.height,
                    data: {
                        model: {
                            ...adData,
                            format: bestFormat.format,
                            language: EditorData.get('language'),
                            editmode: 0,
                            frameNr: 1,
                            positionByLanguage,
                            uploadByLanguage
                        },
                        template
                    }
                };
            default:
                return null;
        }
    };

    /**
     * Find the best format for a preview based on a goal ratio.
     * @param {array} formats
     * @returns {object} the best matching format.
     */
    static getPreviewDataFormat = (formats) => {
        if (!formats) return false;
        const goalRatio = 1.3;
        const ratios = formats.map((f) => {
            return {
                ...f,
                ratio: f.width / f.height
            };
        });
        const best = ratios.reduce((prev, curr) => {
            return Math.abs(curr.ratio - goalRatio) < Math.abs(prev.ratio - goalRatio) ? curr : prev;
        });
        return best;
    };

    /**
     * Import a creative from a campaign to Creative Management
     * @param {object} item
     * @param {object} data
     */
    static importCreative = (item, data) => {
        const previewData = this.getPreviewData(data, 'main');
        const newCreative = {
            templateIdentifier: item.identifier,
            templateType: item.type,
            creativeData: data
        };
        if (data.title) {
            newCreative.title = data.title;
        }

        if (previewData) {
            newCreative.previewData = previewData;
        }

        CreativeManagementRequest.post('assets/creatives', newCreative)
            .then((response) => {
                // If Creative Management is initiated, add the imported creative to the list.
                if (ComponentStore.get('CreativeManagement')) {
                    const newCreative = {
                        ...response.data.asset,
                        uuid: this.createUuid(response.data.asset.referenceId)
                    };
                    this.addCreativeToManagement(newCreative);
                }
                SnackbarUtils.success(Translation.get('feedback.creativeSaved', 'creative-management'));
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('feedback.creativeAddFailed', 'creative-management'));
                console.error(error);
            });
    };

    /**
     * Move a specific creative to the editor store in Redux so it can be accessed by the CreativeEditor.
     * @param {object} creative
     */
    static openCreative = (creative) => {
        EditorData.setModel('creativeManagement', {
            setup: [
                {
                    uuid: creative.uuid,
                    referenceId: creative.referenceId,
                    identifier: creative.templateIdentifier,
                    type: creative.templateType,
                    title: creative.creativeData.title,
                    createdAt: creative.createdAt,
                    creator_user_name: creative.creator_user_name
                }
            ],
            data: {
                [creative.uuid]: creative.creativeData
            }
        });
    };

    /**
     * Delete an array of creatives
     * @callback callback
     * @param {array} selectedCreatives
     * @param {string} type
     * @param {callback}
     */
    static deleteCreatives = (selectedCreatives, type, callback = () => {}) => {
        const promises = [];
        const setup = cloneDeep(ComponentStore.get('CreativeManagement').creativesList);
        selectedCreatives.forEach((creativeId) => {
            promises.push(CreativeManagementRequest.delete(`assets/${creativeId}${type === 'permanent' ? '' : '/bin'}`));
            const index = setup.findIndex((creative) => creative.referenceId === creativeId);
            setup.splice(index, 1);
        });
        Promise.all(promises).then(() => {
            ComponentStore.setModel('CreativeManagement', 'creativesList', setup);
            callback();
        });
    };

    /**
     * Delete an array of creatives
     * @callback callback
     * @param {array} selectedCreatives
     * @param {callback}
     */
    static deleteTemplates = (selectedAssets, callback = () => {}) => {
        const promises = [];
        selectedAssets.forEach((id) => {
            promises.push(
                Request.post('templates/remove', { id: id }).then(() => {
                    SnackbarUtils.success(Translation.get('templateManagement.remove', 'settings'));
                })
            );
        });
        Promise.all(promises).then(() => {
            callback();
        });
    };
    /**
     * Add item to a builder data object
     * This takes the template as the input and sets up the new data object used by the builder
     * @param {*} dataList The list with all the templates in the current cmapaign
     * @param {*} newItemTemplate The item template full JSON as in the DB
     */
    static addNewItemFromCreative(dataList, newItemTemplate = {}) {
        if (!dataList) {
            dataList = [];
        }

        // Add item to list
        const uuid = 'i_' + uuidv4().replace(/-/g, '');
        const newItem = {
            uuid: uuid,
            identifier: newItemTemplate?.templateIdentifier || newItemTemplate.assetSetup.templateIdentifier,
            type: newItemTemplate?.type || newItemTemplate.assetSetup.type
        };
        const dataListNew = [...dataList, newItem];

        // ReturnData
        return {
            uuid,
            dataList: dataListNew,
            itemData: newItemTemplate
        };
    }

    /**
     * Add a crative to the creative list in Creative Management.
     * @param {object} creative
     */
    static addCreativeToManagement = (creative) => {
        const { creativesList } = ComponentStore.get('CreativeManagement');
        creativesList.unshift(creative);
        ComponentStore.setModel('CreativeManagement', 'creativesList', creativesList);
    };

    /**
     * Replace a creative in the creative list in Creative Management.
     * @param {object} creative
     */
    static replaceCreativeInManagement = (creative) => {
        const { creativesList } = ComponentStore.get('CreativeManagement');
        const setupIndex = creativesList.findIndex((c) => c.referenceId === creative.referenceId);
        if (setupIndex > -1) creativesList[setupIndex] = creative;
        ComponentStore.setModel('CreativeManagement', 'creativesList', creativesList);
    };

    /**
     * Try to find a preview image for a creative.
     * @param {object} creative
     * @returns {string} preview image url
     */
    static getPreviewImage = (creative) => {
        if (creative.templateType === 'dynamicVideo') {
            const template = Templates.get('dynamicVideo', creative.templateIdentifier);
            if (template.image) return template.image;
        } else if (this.htmlTemplateBasedCreatives.includes(creative.templateType)) {
            try {
                return creative.files[0].thumbnails[0].url;
            } catch (e) {
                return null;
            }
        }
    };
}

export default CreativeManagementHelpers;
