import { MessageClient } from 'services/messaging-client/MessageClient.service';
import { Message } from 'services/messaging-client/Message';
import FeedHelpers from './feed-helpers';

class FeedSubscription {
    /**
     * Handle websocket messages
     * @param {string} data  Stringified response from websocket
     */
    static onmessage = (message: Message) => {
        switch (message.path) {
            case 'internal/feed-manager/feed-update/started':
                FeedHelpers.patchDataSetUpdate(message.content.datasetId, message.path, {
                    date: message.content.date,
                    feedId: message.content.feedId,
                    pollType: message.content.pollType,
                    errorType: message.content.errorType ? message.content.errorType : null,
                    message: message.content.errorMessage ? message.content.errorMessage : null,
                    isUpdating: true
                });
                break;
            case 'internal/feed-manager/feed-update/finished':
                FeedHelpers.patchDataSetUpdate(message.content.datasetId, message.path, {
                    date: message.content.date,
                    feedId: message.content.feedId,
                    ...message.content.items,
                    operationId: message.content.operationId,
                    errorType: message.content.errorType ? message.content.errorType : null,
                    message: message.content.errorMessage ? message.content.errorMessage : null,
                    isUpdating: false
                });
                break;
            case 'internal/feed-manager/feed-update/failed':
                FeedHelpers.patchDataSetUpdate(message.content.datasetId, message.path, {
                    ...message.content,
                    isUpdating: false
                });
                break;
            case 'internal/feed-manager/export/ready':
                if (message.content && message.content.operationId) {
                    const operationId = message.content.operationId;
                    if (operationId) FeedHelpers.getExport(operationId);
                }
                break;
            case 'internal/feed-manager/export/failed':
                console.error('Export failed', message);
                break;
            case 'internal/feed-manager/auto-publish/started':
                FeedHelpers.patchDataSetPublish(message.content.datasetId, message.path, {
                    error: message.content.errorType,
                    message: message.content.errorMessage,
                    isPublishing: true
                });
                break;
            case 'internal/feed-manager/auto-publish/finished':
                FeedHelpers.patchDataSetPublish(message.content.datasetId, message.path, {
                    date: message.content.date,
                    error: message.content.errorType,
                    message: message.content.errorMessage,
                    isPublishing: false
                });
                break;
            case 'internal/feed-manager/filtering/ready':
                FeedHelpers.reloadDataSet(message.content.datasetId);
                break;
            default:
                return;
        }
    };

    /**
     * Setup a feed management subscription
     */
    static subscribe = () => {
        const messageClient = MessageClient.getInstance();
        return messageClient.subscribeToTopic('internal/feed-manager/*', (message) => {
            FeedSubscription.onmessage(message);
        });
    };

    /**
     * Unsubscribe from feed management subscription
     */
    static unsubscribe = (subscription: string) => {
        const messageClient = MessageClient.getInstance();
        messageClient.unsubscribeFromTopic('internal/feed-manager/*', subscription);
    };
}

export default FeedSubscription;
