import Resources from '../Resources';
import Setup from '../Setup';
import User from '../User';

/**
 * BrandSettings
 * This class gets the brand settings of the current user.
 *
 */
export default class BrandSettings {
    /**
     * Get active brand settings
     */
    static get() {
        let brandSettings = Resources.get('brandSettings');
        if (!brandSettings) {
            brandSettings = {};
        }
    }

    /**
     * Get a field from the interface settings
     * @param {*} field
     */
    static getInterface(field) {
        const brands = User.get('brands');
        let brandSettings = Resources.get('brandSettings');
        if (!brandSettings) {
            brandSettings = {};
        }

        // Loop through all brands
        for (const i in brands) {
            if (brandSettings[i] && brandSettings[i].interface && brandSettings[i].interface[field]) {
                return brandSettings[i].interface[field];
            }
        }
        if (brandSettings.general && brandSettings.general.interface && brandSettings.general.interface[field]) {
            return brandSettings.general.interface[field];
        }

        return false;
    }

    /**
     * Get the advertiser data object
     * @returns object advertiser
     */
    static getAdvertiserData() {
        // Check whether set in setup
        let advertiser = Setup.getValueFromModel('social.advertiser');
        if (!advertiser) {
            advertiser = {
                name: '',
                logo: ''
            };
        }

        // Get data from brand settings
        const brandSettings = Resources.get('brandSettings');
        if (brandSettings && brandSettings.general?.interface?.socialAvatar) {
            advertiser.logo = brandSettings.general.interface.socialAvatar;
        }

        return advertiser;
    }
}
