import React from 'react';
import { connect } from 'react-redux';

/**
 * withComponentStore
 * This wraps the component and passed props from the redux store to the wrapper component.
 * @param {*} WrappedComponent The component that we wrap in this HOC
 * @param {*} componentName The name of the component. This is the key used in the store to save the daa
 * @param {*} includesFields The additional fields. For now  'language' and 'editor'
 */
export default function withComponentStore(WrappedComponent, componentName, includesFields = []) {
    class ComponentStoreHolder extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        /**
         * Use the current componentname to pass as props
         */
        static mapStateToProps(state) {
            let result = {};

            if (state.componentStore && state.componentStore[componentName]) {
                result = {
                    ...state.componentStore[componentName],
                    componentStoreDataLoaded: true
                };
            } else {
                result = {};
            }

            // Add additional fields
            if (includesFields.includes('language')) {
                result.language = state.editor.language;
            }
            if (includesFields.includes('editor')) {
                result.editor = state.editor;
            }

            return result;
        }

        render() {
            const { componentStoreDataLoaded, waitForLoading = true } = this.props;
            if (!waitForLoading || componentStoreDataLoaded) {
                return <WrappedComponent {...this.props} />;
            } else {
                return <React.Fragment />;
            }
        }
    }
    return connect(ComponentStoreHolder.mapStateToProps)(ComponentStoreHolder);
}
