import axios from 'axios';
import User from '../User';

/**
 * Set initial request data
 */

const FeedRequest = axios.create({
    baseURL: process.env.FEED_MANAGER,
    timeout: process.env.APP_API_TIMOUT
});

/**
 * Intercept requests
 * 1.   Add auth information as default to request, will be overridden if is set
 *      in the request data.
 */
FeedRequest.interceptors.request.use(
    (config) => {
        const feedToken = User.get('feedManagerApiToken');
        if (feedToken) {
            config.headers.Authorization = `Bearer ${feedToken}`;
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

export { FeedRequest };
export default FeedRequest;
