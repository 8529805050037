import moment from 'moment'; // eslint-disable-line
import EditorData from 'components/editor-data/EditorData'; // eslint-disable-line
import CreativeBuilderPublishSocialFacebook from 'components/publishing/PublishProfileParser/helpers/creative-builder-publish-social-facebook'; // eslint-disable-line
import PublishHelpers from 'components/data/PublishHelpers'; // eslint-disable-line
import CreativeBuilder from 'components/publishing/PublishProfileParser/helpers/creative-builder'; // eslint-disable-line
import FeedHelpers from 'components/data/FeedManagementHelpers';
import Setup from 'components/data/Setup';
import User from 'components/data/User';
import cloneDeep from 'helpers/cloneDeep';

export default class CustomerHelperScripts {
    constructor() {
        this.EditorData = EditorData;
        this.CreativeBuilderPublishSocialFacebook = CreativeBuilderPublishSocialFacebook;
        this.PublishHelpers = PublishHelpers;
        this.moment = moment;
        this.CreativeBuilder = CreativeBuilder;
        this.FeedHelpers = FeedHelpers;
        this.Setup = Setup;
        this.User = User;
        this.cloneDeep = cloneDeep;
    }

    /**
     * Load functions from resources
     * @param {*} data
     */
    static loadFunctions(data) {
        if (data) {
            Object.keys(data).forEach((key) => {
                const script = data[key];
                try {
                    eval('CustomerHelperScripts.prototype.' + key + ' = ' + script);
                } catch (e) {
                    console.log('Error loading script', e);
                }
            });
        }
    }
}
