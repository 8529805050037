import Axios from 'axios';
import moment from 'moment';
import { merge } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Request from 'components/data/Request';
import DataHelpers from '../DataHelpers';
export default class AssetHelpers {
    /**
     * Perform API call to get an access token to the content space API
     */
    static getToken = async () => {
        const data = await Request.post('contentspace/getToken', {});

        if (data.success === 1) {
            return data.data.token;
        }

        return null;
    };

    /**
     * Reorder an array of assets received from the API to match the data needed by the frontend.
     * @param {array} data
     * @returns Array of mapped asset objects
     */
    static mapAssets = (data) =>
        data.map((obj) => {
            return AssetHelpers.mapAsset(obj);
        });

    /**
     * Reorder an asset received from the API to match the data needed by the frontend.
     * @param {object} asset
     * @returns Mapped asset object
     */
    static mapAsset = (asset) => {
        let file = {};
        let thumbnail = '';

        try {
            file = asset.files[0];

            if (file.mediumThumbnail) thumbnail = file.mediumThumbnail.url;
        } catch (e) {
            console.log('Error ContentSpace', e);
        }

        let extension = file.url.split(/\#|\?/)[0].split('.').pop().trim(); // eslint-disable-line

        // Only accept mp4 files with video's, so take the conversion mp4 file if the original one has different extension
        if (asset.category === 'video' && extension !== 'mp4' && file.conversions) {
            const conversionFile = file.conversions[0];
            if (conversionFile) {
                file.url = conversionFile.url;
                extension = conversionFile.conversion;
                file.humanSize = conversionFile.humanSize;
                file.size = conversionFile.size;
            }
        }

        return {
            extension,
            fileType: asset.category,
            title: asset.title,
            size: file.size,
            humanSize: file.humanSize,
            thumbnail,
            url: file.url,
            preview: file.preview,
            width: file.width ? file.width : '',
            height: file.height ? file.height : ''
        };
    };

    /**
     * Only show unique results
     * @param {array} array
     */
    static uniqueResults = (array) => {
        const result = [];
        const map = new Map();
        for (const item of array) {
            if (!map.has(item.url)) {
                map.set(item.url, true); // set any value to Map
                result.push(item);
            }
        }
        return result;
    };

    /**
     * Get a single Asset from the API.
     * @param {string} referenceId
     * @param {string} token
     * @returns asset
     */
    static getAsset = async (referenceId, token) => {
        if (!token) {
            token = await AssetHelpers.getToken();
        }
        const response = await Axios.get(
            `${process.env.APP_ASSET_LIBRARY_URL}/assets/${referenceId}`,
            { headers: { Authorization: token } },
            { withCredentials: false }
        );

        if (response && response.data) {
            return response.data;
        }
        return false;
    };
    /**
     * Get a single Collection from the API.
     * @param {string} referenceId
     * @param {string} token
     * @returns collection
     */
    static getCollection = async (collectionReferenceId, token) => {
        if (!token) {
            token = await AssetHelpers.getToken();
        }
        const response = await Axios.get(
            `${process.env.APP_ASSET_LIBRARY_URL}/collections/${collectionReferenceId}`,
            { headers: { Authorization: token } },
            { withCredentials: false }
        );

        if (response && response.data) {
            return response.data.collection;
        }
        return false;
    };

    /**
     * Start Visual Analysis for an asset.
     * @param {string} referenceId
     * @param {string | undefined} token
     * @returns Visual Analysis object of an asset
     */
    static startVisualAnalysis = async (referenceId, token) => {
        if (!token) {
            token = await AssetHelpers.getToken();
        }
        const response = await Axios.get(
            `${process.env.APP_ASSET_LIBRARY_URL}/visualanalysis/analyse/${referenceId}`,
            { headers: { Authorization: token } },
            { withCredentials: false }
        );

        if (response && response.data) {
            return response.data;
        }
        return false;
    };

    /**
     * Patch a single asset on the API.
     * @param {string} referenceId
     * @param {object} data
     * @param {string} token
     */
    static patchAsset = async (referenceId, data, token) => {
        // The backend wants the custom data as separate key value pairs
        const newData = {
            ...data,
            ...data.custom
        };
        delete newData.custom;

        await Axios.put(
            `${process.env.APP_ASSET_LIBRARY_URL}/assets/${referenceId}`,
            newData,
            { headers: { Authorization: token } },
            { withCredentials: false }
        )
            .then(() => {})
            .catch((err) => {
                console.log('error', err);
            });
    };

    /**
     * Add a single asset to a collection.
     * Mind a patch on the collections key of the asset itself is also needed.
     * @param {string} collectionReferenceId
     * @param {string} assetReferenceId
     * @param {string} token
     */
    static addAssetToCollection = async (collectionReferenceId, assetReferenceId, token) => {
        await Axios.post(
            `${process.env.APP_ASSET_LIBRARY_URL}/collections/${collectionReferenceId}/items`,
            { assetId: assetReferenceId },
            { headers: { Authorization: token } },
            { withCredentials: false }
        )
            .then(() => {})
            .catch((err) => {
                console.log('error', err);
            });
    };

    /**
     * Get the metadata inputs for whish an inpt field is required.
     * @param {array} metadataInputs
     * @returns array of editable metadata inputs.
     */
    static getInputs = (metadataInputs) => {
        return Array.isArray(metadataInputs) ? [...metadataInputs].filter((i) => i.editable) : [];
    };

    /**
     * Get the requiered inputs.
     * @param {array} inputs
     * @returns array of required inputs
     */
    static getRequiredInputs = (inputs) => {
        return Array.isArray(inputs) ? [...inputs].filter((i) => i.required).map((i) => i.key) : [];
    };

    /**
     * Get the right accept value for the dropzone.
     * @param {*} fileType
     * @returns array
     */
    static getAccept = (fileType) => {
        let accept = [];
        if (fileType instanceof Array) {
            accept = fileType.map((x) => {
                if (x === 'video') {
                    return 'video/*';
                } else if (x === 'audio') {
                    return 'audio/*';
                } else if (x === 'image') {
                    return 'image/*';
                } else if (x === 'pdf') {
                    return 'application/pdf';
                } else if (x === 'zip') {
                    return 'application/x-zip-compressed';
                } else {
                    return `.${x}`;
                }
            });
            accept = accept.join(',');
        } else if (fileType === 'video') {
            accept.push('video/*');
        } else if (fileType === 'audio') {
            accept.push('audio/*');
        } else if (fileType === 'pdf') {
            accept.push('application/pdf');
        } else if (fileType === 'zip') {
            accept.push('application/x-zip-compressed');
        } else if (fileType === 'all') {
            accept = null;
        } else {
            accept.push('image/*');
        }
        return accept;
    };

    /**
     * Combine an original asset it's updated state, and shared additions to form a current asset.
     * @param {object} asset
     * @param {object} updatedState
     * @param {object} sharedAdditions
     * @returns {object} the current asset.
     */
    static getCurrentAsset = (asset, updatedState = {}, sharedAdditions = {}) => {
        const updatedAsset = this.applyUpdatedState(asset, updatedState);
        const additions = this.applySharedAdditions(updatedAsset, sharedAdditions);
        const newAsset = merge(updatedAsset, additions);
        return newAsset;
    };

    /**
     * Apply the updated state of an asset on an asset
     * @param {object} asset
     * @param {object} updatedState
     * @returns {object} new asset
     */
    static applyUpdatedState = (asset, updatedState) => {
        let custom = { ...asset.custom };
        if (updatedState && updatedState.custom) {
            custom = {
                ...asset.cutom,
                ...updatedState.custom
            };
        }
        return {
            ...asset,
            ...updatedState,
            custom
        };
    };

    /**
     * Apply shared additions on an asset object
     * @param {object} original
     * @param {object} sharedAdditions
     * @returns {object} asset keys with shared additions applied
     */
    static applySharedAdditions = (asset, sharedAdditions) => {
        /**
         * Get the vale of an array with additions added.
         * @param {array} value original
         * @param {array} additions
         * @returns {array} Combined value
         */
        const getNewValue = (value, additions) => {
            if (value && value.length) return [...new Set([...value, ...additions])];
            return additions;
        };
        const assetSharedAdditions = {};
        Object.keys(sharedAdditions).forEach((key) => {
            if (key === 'custom') {
                Object.keys(sharedAdditions.custom).forEach((customKey) => {
                    DataHelpers.setModel(
                        assetSharedAdditions,
                        `custom.${customKey}`,
                        getNewValue(DataHelpers.getValue(asset, `custom.${customKey}`), sharedAdditions.custom[customKey])
                    );
                });
            } else {
                DataHelpers.setModel(assetSharedAdditions, key, getNewValue(asset[key], sharedAdditions[key]));
            }
        });
        return assetSharedAdditions;
    };

    /**
     * Return a extentio string for popper display based on the xetention sored in the asset.
     * @param {string} extension
     * @returns {string} extention
     */
    static getExtension = (extension) => {
        if (extension === 'x-zip-compressed') return 'zip';
        if (extension === 'vnd.adobe.photoshop') return 'psd';
        if (extension === 'jpeg') return 'jpg';
        if (!extension || extension.length === 0) return 'unknown';
        return extension;
    };

    /**
     * Group the inputs into different sections for display in a default manner, so if no custom grouping is specified.
     * @param {array} inputs
     * @returns array with grouped inputs
     */
    static getDefaultGroupedInputs = (inputs) => {
        const groupedInputs = [
            {
                title: 'Asset title',
                icon: 'title',
                open: true,
                inputs: []
            },
            {
                title: 'Organise',
                icon: 'collections_bookmark',
                open: true,
                inputs: []
            },
            {
                title: 'Availability',
                icon: 'vpn_lock',
                open: true,
                inputs: []
            }
        ];
        inputs.forEach((input) => {
            if (input.key === 'title') {
                groupedInputs[0].inputs.push(input);
            } else if (['subCategories', 'brands', 'departments', 'markets', 'collections', 'tags'].includes(input.key)) {
                groupedInputs[1].inputs.push(input);
            } else {
                groupedInputs[2].inputs.push(input);
            }
        });
        return groupedInputs;
    };
    /**
     * Group the inputs into different sections for display according to a grouping specified in the Content Space setup.
     * @param {array} inputs
     * @param {array} grouping
     * @returns array with grouped inputs
     */
    static getCustomGroupedInputs = (inputs = [], grouping = []) => {
        const groupedInputs = [];
        grouping.forEach((group) => {
            groupedInputs.push({
                ...group,
                inputs: Array.isArray(group.inputs) ? group.inputs.map((input) => inputs.find((i) => i.key === input)).filter((i) => !!i) : []
            });
        });
        return groupedInputs;
    };

    /**
     * Get the current title of an asset.
     * @param {object} asset
     * @returns string title
     */
    static getCurrentAssetTitle = (asset, updatedState = {}) => {
        const currentSingleAsset = AssetHelpers.getCurrentAsset(asset, updatedState[asset.referenceId]);
        if (currentSingleAsset.title && currentSingleAsset.title.length > 0) return currentSingleAsset.title;
        return '...';
    };

    /**
     * Validate an asset against the required inputs.
     * @param {object} asset
     * @param {array} requiredInputKeys
     * @returns array with missing inputs. If array is empty the asset validates.
     */
    static getMissingInputs = (asset, requiredInputKeys) => {
        const missingInputs = [];
        requiredInputKeys.forEach((input) => {
            const target = !asset[input] && asset.custom && asset.custom[input] ? asset.custom[input] : asset[input];
            if (!target || (Array.isArray(target) && target.length === 0)) {
                missingInputs.push(input);
            } else if (input === 'subCategories') {
                if (!AssetHelpers.validateSubCategories(target)) missingInputs.push(input);
            }
        });
        return missingInputs;
    };

    /**
     * Validate the special subCategories
     * @param {*} value
     * @returns boolean indicating whether the field validates.
     */
    static validateSubCategories = (value) => {
        if (!value || !value.length) return false;
        const valueArray = value.split('.');
        if (valueArray.length === 2) return true;
        return false;
    };

    /**
     * Transform assets for display purposes.
     * @param {array} assets
     * @returns transformed array of assets.
     */
    static transformAssets = (assets) => {
        return assets.map((asset) => {
            // find thumbnail
            let mediumThumbnail = null;
            let largeThumbnail = null;
            let url = null;
            let humanSize = null;
            let extension = null;
            let name = asset.name;
            let title = asset.title;

            try {
                const firstFileWithThumbnails = asset.files.find((file) => file.mediumThumbnail && file.largeThumbnail);

                if (firstFileWithThumbnails) {
                    mediumThumbnail = firstFileWithThumbnails.mediumThumbnail.url;
                    largeThumbnail = firstFileWithThumbnails.largeThumbnail.url;
                }

                if (asset && asset.files[0]) {
                    url = asset.files[0].url;
                }
            } catch (err) {
                console.log('Error when transforming assets', err);
            }

            asset.files.forEach((file) => {
                if (file.referenceId === asset.referenceId) {
                    humanSize = file.humanSize;
                    if (!title) {
                        title = file.name;
                    }
                    if (!name) {
                        name = file.name;
                    }
                    extension = file.name.split('.').pop();
                }
            });

            return {
                ...asset,
                url,
                mediumThumbnail,
                largeThumbnail,
                humanSize,
                name,
                title,
                extension
            };
        });
    };

    /**
     * Find a thumbnail in an asset
     * @param {object} asset
     * @returns thumbnail url
     */
    static getAssetThumbnail = (asset) => {
        if (!asset) return null;

        let thumbnail = null;
        if (asset.category === 'image' && asset.files && asset.files[0].mediumThumbnail && asset.files[0].url && !asset.files[0].url.endsWith('.svg')) {
            thumbnail = asset.files[0].mediumThumbnail.url;
        } else if (asset.category === 'image' && asset.files && asset.files[0] && asset.files[0].url && asset.files[0].url.endsWith('.svg')) {
            thumbnail = asset.files[0].url;
        } else if (asset.category === 'archive' && asset.files && asset.files.length > 1 && asset.files[1].thumbnails.length > 0) {
            if (asset.files[1] && asset.files[1].url.endsWith('.svg')) {
                thumbnail = asset.files[1].url;
            } else {
                thumbnail = asset.files[1].thumbnails[0].url;
            }
        } else if (asset.category !== 'image' && asset.files && asset.files[0].thumbnails.length > 0) {
            thumbnail = asset.files[0].thumbnails[0].url;
        }

        return thumbnail;
    };

    /**
     * Check if upload processing for an unknown, font or archive filetype is ready.
     * @param {object} asset
     * @returns {boolean}
     */
    static getUnknownAvailable = (asset) => {
        switch (asset.category) {
            case 'archive':
                return asset.filesCount && asset.files && asset.files.length >= asset.filesCount;
            case 'unknown':
            case 'font':
                return asset.files && asset.files.length > 0 && asset.files[0].key ? true : false;
            default:
                return false;
        }
    };

    /**
     *  During the upload process, we want to know if the asset is ready to be stored.
     * @param {object} asset
     * @param {string} thumbnail
     * @param {boolean} unknownAvailable
     * @returns {boolean}
     */
    static getReadyToStore = (asset, thumbnail, unknownAvailable) => {
        if (!asset.available) return false;
        if (['archive', 'font', 'unknown'].includes(asset.category)) return unknownAvailable;
        return !!thumbnail;
    };

    /**
     * Patch a Redux asset item with new data.
     * @param {object} item item as present in Redux
     * @param {object} data data to patch the item with
     * @returns Patched item
     */
    static patchReduxItem = (item, data) => {
        data.custom = { ...item.custom, ...data.custom };

        if (data.focus_points) {
            const files = item.files.map((file) => {
                const points = data.focus_points.find((i) => i.referenceId === file.referenceId);

                // Add a temp_id to pints that don't have an _id or temp_id yet.
                if (points && points.points) {
                    points.points = points.points.map((p) => {
                        if (!p._id && !p.temp_id) p.temp_id = uuidv4();
                        return p;
                    });
                }

                return { ...file, focus_points: points ? points.points : [] };
            });

            if (item.focus_points) delete item.focus_points;

            item = { ...item, files };
        }

        return { ...item, ...data };
    };

    /**
     * Check if an asset is vailable based on it's available fields.
     * @param {object} item
     * @returns {boolean}
     */
    static isAssetAvailable = (item) => {
        if (item.availableFrom && moment(item.availableFrom).isValid()) {
            if (moment(Date.now()).isBefore(item.availableFrom)) {
                return false;
            }
        }
        if (item.availableUntil && moment(item.availableUntil).isValid()) {
            if (moment(Date.now()).isAfter(item.availableUntil)) {
                return false;
            }
        }
        return true;
    };

    /**
     * Accepted file types can be defined as either string ar array, we want to always use an array.
     * @param {string|array} acceptedTypes
     * @returns {array}
     */
    static getAcceptedFileTypes = (acceptedTypes) => {
        if (!acceptedTypes) return false;
        if (Array.isArray(acceptedTypes)) return acceptedTypes;
        return [acceptedTypes];
    };

    /**
     * Format the collection preview items for proper display in a collection view tile.
     * @param {object} collection
     * @returns {array|boolean}
     */
    static getCollectionPreviewItems = (collection) => {
        if (!collection.preview) return false;
        const filteredPreview = collection.preview.filter((item) => item && item.width > 150);
        return filteredPreview;
    };

    /**
     * Filter out collections that do not contain relevant assets
     * @param {array} collections
     * @param {string|array} acceptedTypes
     * @returns {array}
     */
    static getRelevantCollections = (collections = [], acceptedTypes) => {
        const filteredCollections = [...collections].filter((collection) => collection.assetCount || collection.subCollectionCount);

        // If acceptedFiles is defined, check if the collection holds any of these files. Otherwise, filter the collection out.
        const fileTypes = this.getAcceptedFileTypes(acceptedTypes);

        if (!fileTypes || !fileTypes.length) return filteredCollections;

        const relevantCollections = filteredCollections.filter((col) => {
            let result = false;
            if (Array.isArray(col.categories)) {
                col.categories.forEach((cat) => {
                    if (fileTypes.includes(cat._id) && cat.count > 0) {
                        result = true;
                    }
                });
            }
            return result;
        });
        return relevantCollections;
    };

    /**
     * Get a collection thumbnail to show in collection lists.
     * @param {string} thumbnail
     * @param {array} preview
     * @returns {string|boolean}
     */
    static getCollectionThumbnail = (thumbnail, preview) => {
        if (thumbnail) return thumbnail;
        if (preview.length && preview[0].url) return preview[0].url;
        return false;
    };

    /**
     * If something happens to the contents of a collection, we want to refresh the meta data of it's parent(s), so the collection is shown right in overviews.
     * @param {array} collectionsToCheckParent
     * @param {array} collections
     * @param {object} collectionSubcollections
     * @param {function} fetchCollections
     * @param {function} fetchCollectionItems
     */
    static refreshCollectionParents = (collectionsToCheckParent, collections, collectionSubcollections, fetchCollections, fetchCollectionItems) => {
        let rootCollectionsRefreshed = false;
        const parentCollectionsRefreshed = [];

        collectionsToCheckParent.forEach((collectionReferenceId) => {
            if (collections.some((c) => c.referenceId === collectionReferenceId)) {
                // Refresh the root collections if an asset was added to one of them so asset count in the overview is up to date.
                if (!rootCollectionsRefreshed) {
                    fetchCollections();
                    rootCollectionsRefreshed = true;
                }
            } else {
                Object.entries(collectionSubcollections).forEach(([key, value]) => {
                    if (!parentCollectionsRefreshed.includes(key) && value.some((c) => c.referenceId === collectionReferenceId)) {
                        fetchCollectionItems(key, true);
                        parentCollectionsRefreshed.push(key);
                    }
                });
            }
        });
    };
}
